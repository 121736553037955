import HighligherLayout from '../../../components/HighligherLayout';

const Component = () => {
  return (
    <HighligherLayout label="Usage:">
{`
import { ViewToggler, ViewTogglerItem } from "@digitalsettings/ui-lib";

const Component = () => {
  return (
    <>
      <ViewToggler
        currentViewName={currentView}
        items={views}
        handleViewSelect={setCurrentView}
      />
      <br />
      {currentView === "TreeView" && <div>TreeView</div>}
      {currentView === "ListView" && <div>ListView</div>}
      {currentView === "GridView" && <div>GridView</div>}
    </>
  );
};

export default Component;

const views: ViewTogglerItem[] = [
  {
    iconName: "MdLanguage",
    viewName: "TreeView",
    isDefault: true,
  },
  {
    iconName: "MdAccountTree",
    viewName: "ListView",
    isDefault: false,
  },
  {
    iconName: "BiArchive",
    viewName: "GridView",
    isDefault: false,
  },
];
`}
    </HighligherLayout>
  );
};

export default Component;
