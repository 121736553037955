import HighligherLayout from '../../../components/HighligherLayout';

const Component = () => {
  return (
    <HighligherLayout label="Usage:">
{`
import {
  Button,
  ColorPreview,
  Icon,
  Initials,
  MiniImagePreview,
  QuantitySelector,
  Switch,
  Status,
  Table,
  Toolbar,
  ToolbarSettings,
  ToolbarExportData,
  ToolbarSearch,
  ToolbarFilter,
  ToolbarSort
} from "@digitalsettings/ui-lib";

const Component = () => {
  return (
    <>
      <Toolbar
        Settings={<ToolbarSettings />}
        ExportData={
          <ToolbarExportData
            sendOnEmailHandler={() => console.log("test")}
            fileName="testFileName"
            data={exportData}
          />
        }
        Search={<ToolbarSearch value={""} onChange={() => {}} />}
        Filter={<ToolbarFilter filterCount={0}>Filter Content</ToolbarFilter>}
        Sort={
          <ToolbarSort
            onChange={(option) => {}}
            value={"newest"}
            sortOptions={[
              {
                label: "Newest",
                value: "newest",
                isDefault: true,
                params: { OrderBy: 1 },
              },
              {
                label: "Oldest",
                value: "oldest",
                isDefault: false,
                params: { OrderBy: 2 },
              },
            ]}
          />
        }
      />
      <Table
        noData={"nodata"}
        dragEnabled={true}
        pagesCount={10}
        currentPage={1}
        totalCount={100}
        onPageChange={() => {}}
        rowPerPageCount={10}
        hasSelection={true}
        onSelection={(rows) => console.log(rows)}
        handleRowPerPageChange={() => console.log("handleRowPerPageChange")}
        data={{
          head: [
            { label: "Id" },
            { label: "Custom Icon" },
            { label: "Initials" },
            { label: "Multiple Value" },
            { label: "Image" },
            { label: "Label", isSortable: true },
            { label: "Color Box" },
            { label: "Icon With Label" },
            { label: "Icon" },
            { label: "Start/End Date" },
            { label: "Status" },
            { label: "Publish" },
            { label: "Quantity" },
            { label: "Button" },
          ],
          rows: [
            {
              id: 1,
              name: "row1",
              columns: [
                { label: "323423" },
                {
                  label: (
                    <MiniImagePreview
                      imgAltText="Alt Text"
                      iconName="MdPerson"
                      hasOnlineStatus
                      isOnline
                    />
                  ),
                },
                { label: <Initials initials="AB" /> },
                {
                  label: (
                    <Table.MultipleValue
                      data={[
                        "Meama Georgia",
                        "Meama Germany",
                        "Meama France",
                        "Meama China",
                      ]}
                    />
                  ),
                },
                {
                  label: (
                    <MiniImagePreview
                      imgUrl={"https://picsum.photos/200/200"}
                      imgAltText="Alt Text"
                    />
                  ),
                },
                { label: "Label Text 2" },
                { label: <ColorPreview color="#d2b25e" /> },
                {
                  label: (
                    <Icon
                      noGutters
                      name="MdVerified"
                      color="success"
                      label="Verified"
                    />
                  ),
                },
                {
                  label: (
                    <Icon noGutters name="MdEuro" nonClickable color="black" />
                  ),
                },
                { label: "01/06/2023 - 31/07/2023" },
                {
                  label: (
                    <Status
                      statuses={{
                        success: "success",
                        error: "error",
                        warning: "warning",
                        info: "info",
                      }}
                      status="success"
                    />
                  ),
                },
                { label: <Switch label="Published" /> },
                {
                  label: (
                    <QuantitySelector
                      maxQuantity={5}
                      value={0}
                      onValueChange={() => console.log("test")}
                      size="small"
                    />
                  ),
                },
                {
                  label: (
                    <Button
                      label="Add Products"
                      size="small"
                      onClick={() => console.log("test")}
                      noMargins
                    />
                  ),
                },
              ],
              actions: [
                {
                  label: "Details",
                  icon: "MdVisibility",
                  onClick: (item) => console.log("click"),
                },
                {
                  label: "Edit",
                  icon: "MdModeEdit",
                  onClick: (item) => console.log("click"),
                },
                {
                  label: "Remove",
                  icon: "MdDelete",
                  onClick: (item) => console.log("click"),
                },
              ],
            },
            {
              id: 2,
              name: "row2",
              columns: [
                { label: "323423" },
                {
                  label: (
                    <MiniImagePreview
                      imgAltText="Alt Text"
                      iconName="MdPerson"
                      hasOnlineStatus
                      isOnline
                    />
                  ),
                },
                { label: <Initials initials="AB" /> },
                {
                  label: (
                    <Table.MultipleValue
                      data={[
                        "Meama Georgia",
                        "Meama Germany",
                        "Meama France",
                        "Meama China",
                      ]}
                    />
                  ),
                },
                {
                  label: (
                    <MiniImagePreview
                      imgUrl={"https://picsum.photos/200/200"}
                      imgAltText="Alt Text"
                    />
                  ),
                },
                { label: "Label Text 2" },
                { label: <ColorPreview color="#d2b25e" /> },
                {
                  label: (
                    <Icon
                      noGutters
                      name="MdVerified"
                      color="success"
                      label="Verified"
                    />
                  ),
                },
                {
                  label: (
                    <Icon noGutters name="MdEuro" nonClickable color="black" />
                  ),
                },
                { label: "01/06/2023 - 31/07/2023" },
                {
                  label: (
                    <Status
                      statuses={{
                        success: "success",
                        error: "error",
                        warning: "warning",
                        info: "info",
                      }}
                      status="error"
                    />
                  ),
                },
                { label: <Switch label="Published" /> },
                {
                  label: (
                    <QuantitySelector
                      maxQuantity={5}
                      value={0}
                      onValueChange={() => console.log("test")}
                      size="small"
                    />
                  ),
                },
                {
                  label: (
                    <Button
                      label="Add Products"
                      size="small"
                      onClick={() => console.log("test")}
                      noMargins
                    />
                  ),
                },
              ],
              actions: [
                {
                  label: "Details",
                  icon: "MdVisibility",
                  onClick: (item) => console.log("click"),
                },
                {
                  label: "Edit",
                  icon: "MdModeEdit",
                  onClick: (item) => console.log("click"),
                },
                {
                  label: "Remove",
                  icon: "MdDelete",
                  onClick: (item) => console.log("click"),
                },
              ],
            },
          ],
        }}
      />
    </>
  );
};

export default Component;

const exportData = [
  { firstname: 'First Name 1', email: 'test@gmail.com1' },
  { firstname: 'First Name 2', email: 'test@gmail.com2' },
];
`}
    </HighligherLayout>
  );
};

export default Component;
