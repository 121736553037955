import HighligherLayout from '../../../components/HighligherLayout';

const Component = () => {
  return (
    <HighligherLayout label="Usage:">
{`
import { Alert } from "@digitalsettings/ui-lib";

const Component = () => {
  return (
    <Alert
      title="Alert Title"
      text="Lorem ipsum dolor sit amet consectetur adipisicing elit. Ab, dolore voluptatum esse eaque placeat magni."
      variant="info"
    />
  );
};

export default Component;
`}
    </HighligherLayout>
  );
};

export default Component;
