import HighligherLayout from '../../../components/HighligherLayout';

const Component = () => {
  return (
    <HighligherLayout label="Source:">
{`
import React, { useState, memo, useEffect, useMemo, ReactNode } from 'react';
import clsx from 'clsx';
import MuiSelect, { SelectProps as MuiSelectProps } from '@mui/material/Select';

import Checkbox from '../Checkbox';
import Collapse from '../Collapse';
import Search from '../Search';
import FormControl from '../FormControl';
import Icon from '../Icon';
import InputLabel from '../InputLabel';
import MenuItem from '../MenuItem';
import InputAdornment from '../InputAdornment';
import Loader from '../Loader';
import NoData from '../NoData';

import {
  findAllChildrenIds,
  findItemBySearchQuery,
  findItemsByIds,
  findParents,
  isChildrenChecked,
  getObjIds,
  isHalfCheckedItem,
  formatSelectValue,
} from '../../utils';
import { useDebounce } from '../../hooks';

import './style.css';

export type DataItem = {
  // id: string | number;
  // label: string;
  children?: DataItem[] | [];
  [name: string]: any;
};

export type IDataItemKey = keyof DataItem;

const selectModes = {
  singleSelect: 'singleSelect',
  multiSelect: 'multiSelect',
  nestedSingleSelect: 'nestedSingleSelect',
  multiSelectWithChildAndParent: 'multiSelectWithChildAndParent',
  multiSelectWithChildNoParent: 'multiSelectWithChildNoParent',
} as const;

type ItemId = string | number;

type UseCustomSelectProps = {
  data?: DataItem[];
  dataId: IDataItemKey;
  dataLabel: IDataItemKey;
  selectedItems?: number | string | (string | number)[];
  isAsyncSelect?: boolean;
  loadOptions?: (query: string) => Promise<DataItem[]>;
  extraTextMode?: 'default' | 'withChildren';
  checkedChildrenIds?: ItemId[];
  defaultId?: ItemId;
  mode: keyof typeof selectModes;
  minChars?: number;
  maxVisibleItems: number;
  onValueChange: (val: any, id?: ItemId | ItemId[]) => void;
};

const useCustomSelect = ({
  data,
  dataId,
  dataLabel,
  selectedItems,
  mode,
  isAsyncSelect,
  loadOptions,
  extraTextMode,
  checkedChildrenIds,
  defaultId,
  minChars = 1,
  maxVisibleItems = 20,
  onValueChange,
}: UseCustomSelectProps) => {
  const [selectValues, setSelectValues] = useState<DataItem[]>([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [expanded, setExpanded] = useState<Record<any, any>>({});
  const [isLoading, setIsLoading] = useState(false);
  const [fetchedData, setFetchedData] = useState<DataItem[]>([]);

  const debouncedSearchQuery = useDebounce(searchQuery, 500);
  const passedMinChars = minChars <= debouncedSearchQuery.length;

  const filteredItems = useMemo(() => {
    if (isAsyncSelect && !passedMinChars) {
      return [];
    }

    if (debouncedSearchQuery && data) {
      return findItemBySearchQuery(data, debouncedSearchQuery);
    }
    return data || [];
  }, [debouncedSearchQuery, data]);

  const selectedIds = useMemo(() => {
    return selectValues.map((item) => item[dataId]);
  }, [selectValues]);

  const toggleExpand = (node: any) => {
    setExpanded({
      ...expanded,
      [node[dataId]]: !expanded[node[dataId]],
    });
  };

  const isExpanded = (node: any) => {
    return expanded[node[dataId]];
  };

  const removeItems = (items: DataItem[]) => {
    setSelectValues((prev) => {
      const itemsToReturn = prev.filter(({ id }) => {
        return !items.find((child) => child[dataId] === id);
      });
      triggerChange(itemsToReturn.map(({ id }) => id));
      return itemsToReturn;
    });
  };

  const filterById = (selectedItem: DataItem) => {
    setSelectValues((prev) => {
      const items = prev.filter((item) => item[dataId] !== selectedItem[dataId]);
      triggerChange(items.map((item) => item[dataId]));
      return items;
    });
  };

  const mapById = (item: DataItem, dataId: keyof DataItem) => {
    return item[dataId];
  };

  const addAndRemove = (itemsToAdd: DataItem[], itemsToRemove: DataItem[]) => {
    setSelectValues((prev) => {
      const itemsToReturn = [...prev, ...itemsToAdd].filter((item) => {
        return !itemsToRemove.find((child) => child[dataId] === item[dataId]);
      });

      triggerChange(itemsToReturn.map((item) => item[dataId]));
      return itemsToReturn;
    });
  };

  const filterAndRemove = (selectedItem: DataItem, items: DataItem[]) => {
    setSelectValues((prev) => {
      let itemsToReturn = prev.filter(({ id }) => id !== selectedItem[dataId]);

      itemsToReturn = itemsToReturn.filter(({ id }) => {
        return !items.find((child) => child[dataId] === id);
      });

      triggerChange(itemsToReturn.map(({ id }) => id));
      return itemsToReturn;
    });
  };

  const clearSelectedValues = () => {
    setSelectValues([]);
    triggerChange([]);
  };

  const triggerChange = (selectedIds: (string | number)[]) => {
    selectedIds = Array.from(new Set(selectedIds));

    if (extraTextMode === 'default') {
      if (!defaultId) {
        onValueChange(selectedIds, selectedIds[0]);
      } else {
        onValueChange(selectedIds, selectedIds.includes(defaultId) ? defaultId : selectedIds[0]);
      }
    } else if (extraTextMode === 'withChildren') {
      if (checkedChildrenIds?.length) {
        onValueChange(
          selectedIds,
          checkedChildrenIds.filter((id) => selectedIds.includes(id))
        );
      }
    } else {
      onValueChange(
        mode === selectModes.singleSelect || mode === selectModes.nestedSingleSelect ? selectedIds[0] : selectedIds
      );
    }
  };

  const selectItem = (selectedItem: DataItem) => {
    if (mode === selectModes.singleSelect) {
      setSelectValues([selectedItem]);
      triggerChange([selectedItem[dataId]]);
    } else if (mode === selectModes.multiSelect) {
      setSelectValues((prev) => {
        const items = [...prev, selectedItem];
        triggerChange(items.map((item) => item[dataId]));
        return items;
      });
    } else if (mode === selectModes.nestedSingleSelect) {
      setSelectValues([selectedItem]);
      triggerChange([selectedItem[dataId]]);
    } else if (mode === selectModes.multiSelectWithChildAndParent) {
      const chidlIds = findAllChildrenIds(selectedItem);
      const parents = findParents(selectedItem, filteredItems, dataId);

      const parentsIdsToCheck: DataItem[] = [];
      const checkedIds = [...selectedIds, selectedItem[dataId], getObjIds(chidlIds, dataId)];
      parents.forEach((parent) => {
        if (isChildrenChecked(parent, checkedIds, dataId)) {
          parentsIdsToCheck.push(parent);
          // determine if parent parent has checked item
          checkedIds.push(parent[dataId]);
        }
      });
      setSelectValues((prev) => {
        const items = [...prev, selectedItem, ...chidlIds, ...parentsIdsToCheck];
        triggerChange(items.map((item) => item[dataId]));
        return items;
      });
    } else if (mode === selectModes.multiSelectWithChildNoParent) {
      const chidlIds = findAllChildrenIds(selectedItem);
      const parents = findParents(selectedItem, filteredItems, dataId);

      addAndRemove([selectedItem], [...chidlIds, ...parents]);
    }
  };

  const deSelectItem = (selectedItem: DataItem) => {
    if (mode === selectModes.singleSelect) {
      clearSelectedValues();
    } else if (mode === selectModes.multiSelect) {
      filterById(selectedItem);
    } else if (mode === selectModes.nestedSingleSelect) {
      clearSelectedValues();
    } else if (mode === selectModes.multiSelectWithChildAndParent) {
      const childrenIds = findAllChildrenIds(selectedItem);
      const parents = findParents(selectedItem, filteredItems, dataId);

      filterAndRemove(selectedItem, [...childrenIds, ...parents]);
    } else if (mode === selectModes.multiSelectWithChildNoParent) {
      filterById(selectedItem);
    }
  };

  const handleSelect = (selectedItem: DataItem, isChecked: boolean) => {
    if (isChecked) {
      deSelectItem(selectedItem);
    } else {
      selectItem(selectedItem);
    }
  };

  useEffect(() => {
    if (selectedItems && data) {
      const foundItems = findItemsByIds(data, Array.isArray(selectedItems) ? selectedItems : [selectedItems], dataId);
      setSelectValues(foundItems || []);
    }
  }, [selectedItems, data]);

  useEffect(() => {
    if (isAsyncSelect && loadOptions && passedMinChars) {
      setIsLoading(true);

      loadOptions(debouncedSearchQuery)
        .then((data) => {
          setFetchedData(data);
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [isAsyncSelect, debouncedSearchQuery]);

  const itemsToRender = isAsyncSelect ? (fetchedData.length ? fetchedData : selectValues) : filteredItems;
  const checkedItems = isAsyncSelect && !passedMinChars ? selectValues : itemsToRender;

  const hasMoreThenAllowedMaxItems = data?.length && data?.length > maxVisibleItems;
  const isSearchShown = isAsyncSelect || hasMoreThenAllowedMaxItems;

  return {
    selectValues,
    searchQuery,
    expanded,
    selectedIds,
    setSearchQuery,
    toggleExpand,
    isExpanded,
    handleSelect,
    filteredItems,
    debouncedSearchQuery,
    isLoading,
    isSearchShown,
    fetchedData,
    itemsToRender: checkedItems.slice(0, maxVisibleItems),
    passedMinChars,
  };
};

export type SelectProps = MuiSelectProps & {
  data?: DataItem[];
  dataId?: IDataItemKey;
  dataLabel?: IDataItemKey;
  selectedItems?: number | string | (string | number)[];
  handelAsyncSearch?: () => void;
  extraTextMode?: 'default' | 'withChildren';
  defaultId?: ItemId;
  checkedChildrenIds?: ItemId[];
  isAsyncSelect?: boolean;
  minSearchChar?: number;
  maxVisibleItems?: number;
  loadOptions?: (query: string) => Promise<DataItem[]>;
} & (
    | {
        mode: 'singleSelect' | 'nestedSingleSelect';
        onValueChange: (val: ItemId, id?: ItemId | ItemId[]) => void;
      }
    | {
        mode: 'multiSelect' | 'multiSelectWithChildAndParent' | 'multiSelectWithChildNoParent';
        onValueChange: (val: ItemId[], id?: ItemId | ItemId[]) => void;
      }
  );

export const CustomSelect = ({
  data,
  dataId = 'id',
  dataLabel = 'label',
  onValueChange,
  selectedItems,
  mode = selectModes.multiSelectWithChildNoParent,
  defaultId,
  checkedChildrenIds,
  extraTextMode,
  isAsyncSelect = false,
  minSearchChar = 3,
  maxVisibleItems = 20,
  loadOptions,
  ...props
}: SelectProps) => {
  if (data?.length) {
    if (!data[0].hasOwnProperty(dataId)) {
      console.log('does not exists');
      throw new Error('Please, provide correct dataId');
    } else if (!data[0].hasOwnProperty(dataLabel)) {
      throw new Error('Please, provide correct labelId');
    }
  }

  const {
    handleSelect,
    isExpanded,
    isSearchShown,
    selectValues,
    setSearchQuery,
    toggleExpand,
    selectedIds,
    debouncedSearchQuery,
    isLoading,
    searchQuery,
    itemsToRender,
    passedMinChars,
  } = useCustomSelect({
    data,
    dataId,
    dataLabel,
    selectedItems,
    isAsyncSelect,
    loadOptions,
    checkedChildrenIds,
    extraTextMode,
    defaultId,
    onValueChange,
    mode,
    maxVisibleItems,
    minChars: minSearchChar,
  });

  const handleSearchInputChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const searchText = event.target.value;
    setSearchQuery(searchText);
  };

  const getExtraText = (itemId: string | number, isCheckedItem: boolean) => {
    if (!isCheckedItem) return '';

    if (extraTextMode === 'default') {
      if (defaultId === itemId) {
        return (
          <>
            <Icon name="MdHome" size="small" noGutters nonClickable />{' '}
            <span className="MuiSelect-extra-text-non-clickable">Default</span>
          </>
        );
      } else {
        return <span>Set As Default</span>;
      }
    } else if (extraTextMode === 'withChildren') {
      if (checkedChildrenIds?.includes(itemId)) {
        return <span>With Children</span>;
      } else {
        return <span>Check With Children</span>;
      }
    }

    return '';
  };

  const handelExtraTextClick = (item: DataItem) => {
    if (extraTextMode === 'default') {
      // @ts-ignore
      onValueChange(selectedIds as ItemId[], item[dataId]);
    } else if (extraTextMode === 'withChildren') {
      if (!checkedChildrenIds) return;

      const found = checkedChildrenIds.find((cid) => cid === item[dataId]);

      if (found) {
        onValueChange(
          // @ts-ignore
          selectedIds,
          checkedChildrenIds.filter((cid) => cid !== item[dataId])
        );
      } else {
        // @ts-ignore
        onValueChange(selectedIds, [...checkedChildrenIds, item[dataId]]);
      }
    }
  };

  const renderNode = (item: DataItem) => {
    const isItemChecked = !!selectValues.find((selectedItem) => selectedItem[dataId] === item[dataId]);
    const isOpened = isExpanded(item);
    const isIntermediate =
      mode === selectModes.multiSelectWithChildAndParent &&
      !isItemChecked &&
      isHalfCheckedItem(item, selectedIds, dataId);

    return (
      <div key={item[dataId]}>
        <CustomMenuItem
          isOpened={isOpened}
          toggleChildren={() => toggleExpand(item)}
          isChecked={isItemChecked}
          label={item[dataLabel]}
          value={item[dataId]}
          handelSelect={() => handleSelect(item, isItemChecked)}
          hasChildren={!!item.children?.length}
          isIntermediate={isIntermediate}
          extraText={getExtraText(item[dataId], isItemChecked)}
          handelExtraTextClick={() => handelExtraTextClick(item)}
        />
        <Collapse in={isOpened} timeout="auto" unmountOnExit className="MuiSelect-collapse-box">
          {!debouncedSearchQuery && item.children?.map((childItem) => renderNode(childItem))}
        </Collapse>
      </div>
    );
  };

  return (
    <FormControl>
      {props.label && <InputLabel>{props.label}</InputLabel>}
      <MuiSelect
        className={clsx('MuiSelect-wrapper', { 'MuiTextField-hasLabel': props.label })}
        value={selectValues.map((item) => item[dataLabel])}
        multiple={true}
        renderValue={formatSelectValue}
        IconComponent={() => (
          <InputAdornment position="end">
            <Icon name="MdKeyboardArrowDown" nonClickable noGutters size="medium" />
          </InputAdornment>
        )}
        {...props}
      >
        {isSearchShown && (
          <div className="MuiSelect-search-box">
            <Search value={searchQuery} noGutters onChange={handleSearchInputChange} />
          </div>
        )}

        {isLoading && passedMinChars && <Loader />}
        {!isLoading && itemsToRender.length && itemsToRender.map((item) => renderNode(item))}
        {!isLoading && !itemsToRender.length && !!debouncedSearchQuery && passedMinChars && <NoData />}
      </MuiSelect>
    </FormControl>
  );
};

export default memo<SelectProps>(CustomSelect);

type CustomMenuItemProps = MuiSelectProps & {
  // item: DataItem;
  label: string;
  value: string | number;
  isChecked: boolean;
  handelSelect: () => void;
  toggleChildren: () => void;
  isOpened: boolean;
  isIntermediate?: boolean;
  hasChildren: boolean;
  extraText: ReactNode;
  handelExtraTextClick: () => void;
};

const CustomMenuItem = ({
  label,
  value,
  isChecked,
  handelSelect,
  isOpened,
  toggleChildren,
  isIntermediate = false,
  hasChildren,
  extraText,
  handelExtraTextClick,
}: CustomMenuItemProps) => {
  return (
    <MenuItem value={value} disableBg>
      <Checkbox checked={isChecked} indeterminate={isIntermediate} label={label} onChange={handelSelect} noGutters />
      {extraText && (
        <div onClick={handelExtraTextClick} className="MuiSelect-extra-text">
          {extraText}
        </div>
      )}
      {hasChildren && (
        <div className="MuiSelect-collapse-icon">
          <Icon name={isOpened ? 'MdRemove' : 'MdAdd'} onClick={() => toggleChildren()} size="small" />
        </div>
      )}
    </MenuItem>
  );
};
`}
    </HighligherLayout>
  );
};

export default Component;
