import HighligherLayout from '../../../components/HighligherLayout';

const Component = () => {
  return (
    <HighligherLayout label="Source:">
{`
import { memo } from 'react';
import clsx from 'clsx';
import MuiRadio, { RadioProps as MuiRadioProps } from '@mui/material/Radio';
import FormControlLabel from '../FormControlLabel';
import './style.css';

export type RadioProps = Omit<MuiRadioProps, 'size'> & {
  label?: string;
  size?: 'small' | 'medium' | 'large';
  noGutters?: boolean;
};

export const Radio = ({ label, size, noGutters = false, ...props }: RadioProps) => (
  <FormControlLabel
    className={clsx({
      'size-small': size === 'small',
      'size-medium': size === 'medium',
      'size-large': size === 'large',
      'no-gutters': noGutters,
    })}
    control={<MuiRadio {...props} />}
    label={label}
  />
);

export default memo<RadioProps>(Radio);
`}
    </HighligherLayout>
  );
};

export default Component;
