import HighligherLayout from '../../../components/HighligherLayout';

const Component = () => {
  return (
    <HighligherLayout label="Source:">
{`
import { memo, MouseEvent } from 'react';
import clsx from 'clsx';
import Icon from '../Icon';
import MMenu from '../MMenu';
import MenuItem, { MenuItemProps } from '../MenuItem';
import { useClickEvent, useMediaQuery } from '../../hooks';

import styles from './styles.module.css';

type MItem = MenuItemProps & {
  shouldCloseMenu?: boolean;
};

export type UserMenuProps = {
  name?: string;
  email?: string;
  showBadge?: boolean;
  menuItems: MItem[];
  onClick?: (event: MouseEvent<any>) => void;
};

const UserMenu = ({ name, email, showBadge, onClick, menuItems }: UserMenuProps) => {
  const mediaQueryLg = useMediaQuery('lg');

  const [anchorEl, menuActions] = useClickEvent();
  const open = Boolean(anchorEl);
  const handleClick = (event: MouseEvent<HTMLElement>) => {
    menuActions.open(event);
    onClick?.(event);
  };
  const handleClose = () => {
    menuActions.close();
  };

  return (
    <>
      <div className={styles.box} onClick={handleClick}>
        <div className={clsx(styles.userImageBox, styles.userImageBoxTop)}>
          <Icon name="MdPerson" nonClickable />
          {showBadge && <Icon name="MdNotifications" nonClickable noGutters color="error" className={styles.badge} />}
        </div>
        {!mediaQueryLg && (
          <>
            <div className={clsx(styles.userInfoBox, styles.userInfoBoxTop)}>
              {name && <h2 className={clsx(styles.userName, 'f-bold')}>{name}</h2>}
              {email && <div className={styles.userEmail}>{email}</div>}
            </div>
            <div className={clsx(styles.dropdownArrow, { [styles.active]: open })}>
              <Icon name="MdKeyboardArrowDown" active nonClickable size="small" />
            </div>
          </>
        )}
      </div>
      <MMenu
        size="large"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <li key={1} className={styles.largeBox}>
          <div className={styles.userImageBox}>
            <Icon name="MdPerson" nonClickable />
            {showBadge && <Icon name="MdNotifications" nonClickable noGutters color="error" className={styles.badge} />}
          </div>
          <div className={styles.userInfoBox}>
            {name && <h2 className={clsx(styles.userName, 'f-bold')}>{name}</h2>}
            {email && <div className={styles.userEmail}>{email}</div>}
          </div>
        </li>
        {menuItems?.map(({ children, onClick, shouldCloseMenu = true, ...rest }, i) => {
          const itemClick = (e: MouseEvent<HTMLLIElement>) => {
            onClick?.(e);
            if (shouldCloseMenu) {
              handleClose();
            }
          };

          return (
            <MenuItem key={i} onClick={itemClick} {...rest}>
              {children}
            </MenuItem>
          );
        })}
      </MMenu>
    </>
  );
};

export default memo<UserMenuProps>(UserMenu);
`}
    </HighligherLayout>
  );
};

export default Component;
