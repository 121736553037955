import HighligherLayout from '../../../components/HighligherLayout';

const Component = () => {
  return (
    <HighligherLayout label="Source:">
{`
import { memo } from 'react';
import clsx from 'clsx';
import styles from './styles.module.css';

export type OptionSelectProps = {
  label: string | number;
  isSelected: boolean;
  onClick: () => void;
  size?: 'small' | 'default';
  disabled?: boolean;
};

const OptionSelect = ({ label, isSelected, onClick, size, disabled }: OptionSelectProps) => {
  return (
    <div
      className={clsx(styles.selectButton, {
        [styles.active]: isSelected,
        [styles.small]: size === 'small',
        [styles.disabled]: disabled,
      })}
      onClick={onClick}
    >
      {label}
    </div>
  );
};

export default memo<OptionSelectProps>(OptionSelect);
`}
    </HighligherLayout>
  );
};

export default Component;
