import { ContentLayout, Title, Divider, Alert } from "@digitalsettings/ui-lib";
import Space from "../../components/Space";
import Source from "./Source";
import Usage from "./Usage";

const Component = () => {
  return (
    <ContentLayout title="Alert">
      <Divider />

      <Space>
        <Title variant="h3" gutterBottomSize="xs">
          Example:
        </Title>
        <div>
          <Alert
            title="Alert Title"
            text="Lorem ipsum dolor sit amet consectetur adipisicing elit. Ab, dolore voluptatum esse eaque placeat magni."
            variant="info"
          />
        </div>
      </Space>

      <Space>
        <Usage />
      </Space>
      <Space>
        <Source />
      </Space>
    </ContentLayout>
  );
};

export default Component;
