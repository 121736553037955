import { ContentLayout, Title, Divider, Search } from "@digitalsettings/ui-lib";
import { ChangeEvent, useState } from "react";
import Space from "../../components/Space";
import Source from "./Source";
import Usage from "./Usage";


const Component = () => {
  const [suggestions, setSuggestions] = useState(testSuggestions);

  const handleSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSuggestions(
      testSuggestions.filter(({ label }) =>
        label.includes(e.target.value.toLowerCase())
      )
    );
  };

  return (
    <ContentLayout title="Search">
      <Divider />

      <Space>
        <Title variant="h3" gutterBottomSize="xs">
          Example:
        </Title>
        <div>
          <Search
            hasSuggestions
            minLettersToShowSuggestions={3}
            suggestions={suggestions}
            onChange={handleSearchChange}
          />
        </div>
      </Space>

      <Space>
        <Usage />
      </Space>
      <Space>
        <Source />
      </Space>
    </ContentLayout>
  );
};

export default Component;

const testSuggestions = [
  { label: "Test label 1", value: "val 1" },
  { label: "Test label 2", value: "val 2" },
  { label: "Test label 3", value: "val 3" },
];