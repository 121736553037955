import HighligherLayout from '../../../components/HighligherLayout';

const Component = () => {
  return (
    <HighligherLayout label="Source:">
{`
import { ReactNode, memo } from 'react';
import clsx from 'clsx';
import MDialog from '../MDialog';
import MDialogTitle from '../MDialogTitle';
import MDialogContent from '../MDialogContent';
import MDialogActions from '../MDialogActions';
import Title from '../Title';

import './style.css';

export type DialogProps = {
  size?: 'small' | 'medium' | 'large' | 'extraLarge';
  title?: string;
  subTitle?: string;
  stickyContent?: ReactNode;
  open: boolean;
  content?: ReactNode;
  actions?: ReactNode;
  onClose?: () => void;
};

const CustomDialog = ({
  size = 'large',
  title,
  subTitle,
  stickyContent,
  open,
  content,
  actions,
  onClose,
}: DialogProps) => {
  return (
    <MDialog
      open={open}
      onClose={onClose}
      className={clsx({
        'MuiDialog-size-small': size === 'small',
        'MuiDialog-size-medium': size === 'medium',
        'MuiDialog-size-large': size === 'large',
        'MuiDialog-size-extraLarge': size === 'extraLarge',
      })}
    >
      <MDialogTitle>
        {title && <Title variant="h2">{title}</Title>}
        {subTitle && <Title variant="h3">{subTitle}</Title>}
        {stickyContent}
      </MDialogTitle>
      {content && <MDialogContent>{content}</MDialogContent>}
      {actions && <MDialogActions>{actions}</MDialogActions>}
    </MDialog>
  );
};

export default memo<DialogProps>(CustomDialog);
`}
    </HighligherLayout>
  );
};

export default Component;
