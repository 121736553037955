import HighligherLayout from '../../../components/HighligherLayout';

const Component = () => {
  return (
    <HighligherLayout label="Source:">
{`
import { memo } from "react";
import MuiBreadcrumbs, { BreadcrumbsProps as MuiBreadcrumbsProps } from "@mui/material/Breadcrumbs";
import "./style.css";
import { Link } from "../index";
import { IBreadCrumb } from "../../types/common";

export type BreadcrumbsProps = { breadcrumbs?: IBreadCrumb[] } & MuiBreadcrumbsProps;

export const Breadcrumbs = ({ breadcrumbs, ...rest }: BreadcrumbsProps) => (
  <MuiBreadcrumbs separator="" {...rest}>
    {breadcrumbs?.map(({ to, name, isValidUrl }, i) => (
      <Link key={i} href={isValidUrl ? to : undefined}>
        {name}
      </Link>
    ))}
  </MuiBreadcrumbs>
);

export default memo<BreadcrumbsProps>(Breadcrumbs);
`}
    </HighligherLayout>
  );
};

export default Component;
