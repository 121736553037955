import HighligherLayout from '../../../components/HighligherLayout';

const Component = () => {
  return (
    <HighligherLayout label="Usage:">
{`
import { Title } from "@digitalsettings/ui-lib";

const Component = () => {
  return (
    <Title variant="h1">Title Size H1</Title>
  );
};

export default Component;
`}
    </HighligherLayout>
  );
};

export default Component;
