import HighligherLayout from '../../../components/HighligherLayout';

const Component = () => {
  return (
    <HighligherLayout label="Source:">
{`
import React, { Fragment, memo, ReactNode } from 'react';
import clsx from 'clsx';
import { useMediaQuery } from '../../hooks';
import Title from '../Title';
import { Button, ButtonProps, SpeedDial, SpeedDialProps } from '../index';
import styles from './styles.module.css';

type FooterActionProps = (ButtonProps & {
  posRight?: boolean;
})[];

type HeaderActionProps = ButtonProps[];

export type ContentLayoutProps = {
  children: ReactNode;
  title: string;
  headerComponent?: ReactNode;
  widgets?: ReactNode;
  headerActions?: HeaderActionProps;
  additionalHeaderComponents?: ReactNode;
  footerActions?: FooterActionProps;
};

const getButtonVariant = (i: number): ButtonProps['variant'] => {
  return i === 1 ? 'text' : 'contained';
};

const buttonToSpeedDialMap = (props: ButtonProps[] | []): SpeedDialProps['actions'] => {
  return props?.map((data) => ({
    iconName: data.iconName ?? 'MdAdd',
    label: data.label ?? '',
    onClick: data.onClick as () => void,
  }));
};

const ContentLayout = ({
  children,
  title,
  headerActions,
  footerActions,
  headerComponent,
  additionalHeaderComponents,
  widgets,
}: ContentLayoutProps) => {
  const mediaQueryLG = useMediaQuery('lg');

  const topSection = (
    <>
      <div className={clsx('row', styles.topSection)}>
        <div className="col-6 col-xs-12">
          {title && (
            <div className={styles.title}>
              <Title>{title}</Title>
            </div>
          )}
        </div>
        {headerComponent && (
          <div className="col-6 col-xs-12 d-flex justify-content-end">
            <div className={styles.headerComponent}>{headerComponent}</div>
          </div>
        )}
      </div>
      {widgets && <div className={styles.widgets}>{widgets}</div>}
      {additionalHeaderComponents && <div className={styles.additionalComponents}>{additionalHeaderComponents}</div>}
    </>
  );

  return (
    <>
      {mediaQueryLG && topSection}

      <div className={styles.content}>
        {!mediaQueryLG && topSection}

        {headerActions && (
          <div className={styles.headerActions}>
            {headerActions?.map((o, index) => {
              return (
                <Fragment key={index}>
                  {mediaQueryLG ? (
                    <SpeedDial actions={buttonToSpeedDialMap(headerActions)} />
                  ) : (
                    <Button noMargins label={o.label} onClick={o.onClick} />
                  )}
                </Fragment>
              );
            })}
          </div>
        )}

        <div className={styles.innerContent}>{children}</div>

        {footerActions && (
          <div className={styles.footerActions}>
            {footerActions?.map(({ posRight = false, ...btnProps }, i) => (
              <div key={i} className={clsx({ [styles.positionRight]: posRight })}>
                <Button variant={getButtonVariant(i)} noMargins {...btnProps} />
              </div>
            ))}
          </div>
        )}
      </div>
    </>
  );
};

export default memo<ContentLayoutProps>(ContentLayout);
`}
    </HighligherLayout>
  );
};

export default Component;