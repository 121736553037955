import HighligherLayout from '../../../components/HighligherLayout';

const Component = () => {
  return (
    <HighligherLayout label="Usage:">
{`
import { ColorPreview } from "@digitalsettings/ui-lib";

const Component = () => {
  return (
    <ColorPreview color="#d2b25e" />
  );
};

export default Component;
`}
    </HighligherLayout>
  );
};

export default Component;
