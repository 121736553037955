import { useState } from "react";
import { ContentLayout, Title, Divider, OptionSelect } from "@digitalsettings/ui-lib";
import Space from "../../components/Space";
import Source from "./Source";
import Usage from "./Usage";

const Component = () => {
  const [selected, setSelected] = useState<boolean>(false);

  return (
    <ContentLayout title="Options Select">
      <Divider />

      <Space>
        <Title variant="h3" gutterBottomSize="xs">
          Example:
        </Title>
        <div>
          <OptionSelect
            label="Label"
            isSelected={selected}
            onClick={() => {
              setSelected(!selected);
            }}
          />
        </div>
      </Space>

      <Space>
        <Usage />
      </Space>
      <Space>
        <Source />
      </Space>
    </ContentLayout>
  );
};

export default Component;
