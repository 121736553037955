import HighligherLayout from '../../../components/HighligherLayout';

const Component = () => {
  return (
    <HighligherLayout label="Source:">
{`
// TabContext

import { memo } from 'react';
import MuiTabContext, { TabContextProps as MuiTabContextProps } from '@mui/lab/TabContext';
import './style.css';

export type TabContextProps = MuiTabContextProps;

export const TabContext = ({ ...props }: TabContextProps) => (
  <MuiTabContext {...props} />
);

export default memo<TabContextProps>(TabContext);


// TabList

import { memo } from "react";
import MuiTabList, { TabListProps as MuiTabListProps } from "@mui/lab/TabList";
import './style.css';

export type TabListProps = MuiTabListProps;

export const TabList = ({
  variant = "scrollable",
  ...props
}: TabListProps) => <MuiTabList variant={variant} {...props} />;

export default memo<TabListProps>(TabList);


// Tab

import { memo } from 'react';
import MuiTab, { TabProps as MuiTabProps } from '@mui/material/Tab';
import './style.css';

export type TabProps = MuiTabProps & {
  imgUrl?: string;
};

export const Tab = ({ imgUrl, ...props }: TabProps) => (
  <MuiTab
    icon={
      imgUrl && (
        <div>
          <div className="MuiTab-imgBox">
            <img className="MuiTab-img" src={imgUrl} alt="Tab Img" />
          </div>
        </div>
      )
    }
    {...props}
  />
);

export default memo<TabProps>(Tab);


// TabPanel

import { memo } from 'react';
import MuiTabPanel, { TabPanelProps as MuiTabPanelProps  } from '@mui/lab/TabPanel';
import './style.css';

export type TabPanelProps = MuiTabPanelProps;

export const TabPanel = ({ ...props }: TabPanelProps) => (
  <MuiTabPanel {...props} />
);

export default memo<TabPanelProps>(TabPanel);import { memo } from 'react';
import MuiTabPanel, { TabPanelProps as MuiTabPanelProps  } from '@mui/lab/TabPanel';
import './style.css';

export type TabPanelProps = MuiTabPanelProps;

export const TabPanel = ({ ...props }: TabPanelProps) => (
  <MuiTabPanel {...props} />
);

export default memo<TabPanelProps>(TabPanel);
`}
    </HighligherLayout>
  );
};

export default Component;
