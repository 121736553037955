import HighligherLayout from '../../../components/HighligherLayout';

const Component = () => {
  return (
    <HighligherLayout label="Usage:">
{`
import { Button, toasts } from "@digitalsettings/ui-lib";

const Component = () => {
  return (
    <Button label="Success Toast" onClick={() => toasts.success('Success toast text ...')} />
  );
};

export default Component;
`}
    </HighligherLayout>
  );
};

export default Component;
