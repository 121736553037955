import { ContentLayout, Title, Divider, FileUploader } from "@digitalsettings/ui-lib";
import Space from "../../components/Space";
import Usage from "./Usage";

const Component = () => {
  return (
    <ContentLayout title="File Uploader">
      <Divider />

      <Space>
        <Title variant="h3" gutterBottomSize="xs">
          Example:
        </Title>
        <div>
          <FileUploader
            title="Upload Images"
            subTitle="Recommended Size: 1200x960px"
            urls={[]}
            isMultiple
          />
        </div>
      </Space>

      <Space>
        <Usage />
      </Space>
    </ContentLayout>
  );
};

export default Component;
