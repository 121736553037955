import HighligherLayout from '../../../components/HighligherLayout';

const Component = () => {
  return (
    <HighligherLayout label="Usage:">
{`
import { Status } from "@digitalsettings/ui-lib";

const Component = () => {
  return (
    <Status
      statuses={{
        success: "success",
        error: "error",
        warning: "warning",
        info: "info",
      }}
      status="success"
    />
  );
};

export default Component;
`}
    </HighligherLayout>
  );
};

export default Component;
