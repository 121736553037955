import HighligherLayout from '../../../components/HighligherLayout';

const Component = () => {
  return (
    <HighligherLayout label="Source:">
{`
import { useState, memo, ReactNode } from 'react';
import Collapse from '../Collapse';
import Icon from '../Icon';
import Title from '../Title';
import styles from './styles.module.css';

export type CollapseBoxProps = {
  title?: string;
  subTitle?: string;
  children?: ReactNode;
  isOpen?: boolean;
};

const CollapseBox = ({ isOpen = false, title, subTitle, children, ...props }: CollapseBoxProps) => {
  const [expanded, setExpanded] = useState<boolean>(isOpen);
  const handleExpand = () => setExpanded(!expanded);

  return (
    <div className={styles.wrapper} {...props}>
      <div className={styles.head}>
        <div className={styles.title}>
          <Title variant="h2" subTitle={subTitle}>
            {title}
          </Title>
        </div>

        <div className={styles.arrow}>
          <Icon
            size="medium"
            active
            name={expanded ? 'MdKeyboardArrowUp' : 'MdKeyboardArrowDown'}
            onClick={handleExpand}
          />
        </div>
      </div>

      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <div className={styles.content}>{children}</div>
      </Collapse>
    </div>
  );
};

export default memo<CollapseBoxProps>(CollapseBox);
`}
    </HighligherLayout>
  );
};

export default Component;