import HighligherLayout from '../../../components/HighligherLayout';

const Component = () => {
  return (
    <HighligherLayout label="Source:">
{`
import { memo } from 'react';
import clsx from 'clsx';
import MuiCheckbox, { CheckboxProps as MuiCheckboxProps } from '@mui/material/Checkbox';
import FormControlLabel from '../FormControlLabel';
import './style.css';

export type CheckboxProps = Omit<MuiCheckboxProps, 'size'> & {
  label?: string;
  size?: 'small' | 'medium' | 'large';
  noGutters?: boolean;
};

export const Checkbox = ({ label, size, noGutters = false, ...props }: CheckboxProps) => (
  <FormControlLabel
    className={clsx({
      'size-small': size === 'small',
      'size-medium': size === 'medium',
      'size-large': size === 'large',
      'no-gutters': noGutters,
    })}
    control={<MuiCheckbox {...props} />}
    label={label}
  />
);

export default memo<CheckboxProps>(Checkbox);
`}
    </HighligherLayout>
  );
};

export default Component;
