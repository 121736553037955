import HighligherLayout from '../../../components/HighligherLayout';

const Component = () => {
  return (
    <HighligherLayout label="Source:">
{`
import { toKebabCase } from '../../utils';
import clsx from 'clsx';
import styles from './styles.module.css';

type Colors = 'success' | 'error' | 'warning' | 'info';

type IStatuses = {
  [name: string]: Colors;
};

const defaultStatuses: IStatuses = {
  success: 'success',
  error: 'error',
  warning: 'warning',
  info: 'info',
};

export type StatusProps<T> = {
  statuses: T;
  status: keyof T | keyof typeof defaultStatuses;
};

function Status<T extends IStatuses>({ statuses, status }: StatusProps<T>) {
  const statusColor = { ...statuses, ...defaultStatuses }[toKebabCase(status as string)];

  return <span className={clsx(styles.status, 'status-' + statusColor)}>{status as string}</span>;
}

export default Status;
`}
    </HighligherLayout>
  );
};

export default Component;