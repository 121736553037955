import HighligherLayout from '../../../components/HighligherLayout';

const Component = () => {
  return (
    <HighligherLayout label="Usage:">
{`
import { SpeedDial } from "@digitalsettings/ui-lib";

const Component = () => {
  return (
    <SpeedDial
      actions={[
        { iconName: "MdAdd", label: "Add New", onClick: () => {} },
        { iconName: "MdDelete", label: "Delete", onClick: () => {} },
      ]}
    />
  );
};

export default Component;
`}
    </HighligherLayout>
  );
};

export default Component;
