import HighligherLayout from '../../../components/HighligherLayout';

const Component = () => {
  return (
    <HighligherLayout label="Usage:">
{`
import { MiniFileUploader } from "@digitalsettings/ui-lib";

const Component = () => {
  return (
    <MiniFileUploader
      imageUrl="img_url_here"
      text="Image Title"
      onFileSelect={(file) => {
        console.log(file);
      }}
    />
  );
};

export default Component;
`}
    </HighligherLayout>
  );
};

export default Component;
