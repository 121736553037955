import HighligherLayout from '../../../components/HighligherLayout';

const Component = () => {
  return (
    <HighligherLayout label="Usage:">
{`
import { ContentLayout, TextField } from "@digitalsettings/ui-lib";

const Component = () => {
  return (
    <ContentLayout
      title="Page Title"
      headerComponent={<TextField noGutters label="Select Company" value="MeamaHQ" />}
      headerActions={[
        {
          iconName: 'MdAdd',
          label: 'Add New',
          onClick: () => console.log('Add New'),
        },
      ]}
      footerActions={[
        {
          label: 'Save',
          onClick: () => console.log('Save'),
        },
        {
          label: 'Cancel',
          onClick: () => console.log('Cancel'),
        },
        {
          label: 'Continue',
          onClick: () => console.log('Continue'),
          variant: 'faded',
          posRight: true,
        },
      ]}
    >
      // Content ...
    </ContentLayout>
  );
};

export default Component;
`}
    </HighligherLayout>
  );
};

export default Component;
