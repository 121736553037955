import { ContentLayout, Title, Divider } from "@digitalsettings/ui-lib";
import Space from "../../components/Space";
import Source from "./Source";
import Usage from "./Usage";

const Component = () => {
  return (
    <ContentLayout title="Title">
      <Divider />

      <Space>
        <Title variant="h3" gutterBottomSize="xs">
          Example:
        </Title>
        <div>
          <Title variant="h1">Title Size H1</Title>
        </div>
      </Space>

      <Space>
        <Usage />
      </Space>
      <Space>
        <Source />
      </Space>
    </ContentLayout>
  );
};

export default Component;
