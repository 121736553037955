import { ContentLayout, Title, Divider, Breadcrumbs } from "@digitalsettings/ui-lib";
import Space from "../../components/Space";
import Source from "./Source";
import Usage from "./Usage";

const Component = () => {
  return (
    <ContentLayout title="Breadcrumbs">
      <Divider />

      <Space>
        <Title variant="h3" gutterBottomSize="xs">
          Example:
        </Title>
        <div>
          <Breadcrumbs
            breadcrumbs={[
              {
                name: "Home",
                to: "/",
                isValidUrl: true,
              },
              {
                name: "Ecommerce",
                to: "/",
                isValidUrl: true,
              },
              {
                name: "Catalog",
                to: "/",
                isValidUrl: false,
              },
              {
                name: "Add Or Update",
                to: "/",
                isValidUrl: false,
              },
            ]}
          />
        </div>
      </Space>

      <Space>
        <Usage />
      </Space>
      <Space>
        <Source />
      </Space>
    </ContentLayout>
  );
};

export default Component;
