import HighligherLayout from '../../../components/HighligherLayout';

const Component = () => {
  return (
    <HighligherLayout label="Source:">
{`
import { ReactNode, memo } from 'react';
import clsx from 'clsx';
import styles from './styles.module.css';

export type InitialsProps = {
  bgColor?: string;
  color?: string;
  initials?: ReactNode;
  size?: 'small' | 'medium' | 'large';
};

const Initials = ({ bgColor, color, initials, size = 'medium' }: InitialsProps) => {
  return (
    <div
      style={{ backgroundColor: bgColor ?? '' }}
      className={clsx(styles.box, 'f-bold', 'f-upper', {
        [styles.small]: size === 'small',
        [styles.medium]: size === 'medium',
        [styles.large]: size === 'large',
      })}
    >
      {initials && (
        <div className={styles.text} style={{ color: color ?? '' }}>
          {initials}
        </div>
      )}
    </div>
  );
};

export default memo<InitialsProps>(Initials);
`}
    </HighligherLayout>
  );
};

export default Component;