import HighligherLayout from '../../../components/HighligherLayout';

const Component = () => {
  return (
    <HighligherLayout label="Source:">
{`
import { memo } from 'react';
import clsx from 'clsx';
import Icon from '../Icon';
import { IconName } from '../Icon/icons';
import Title from '../Title';
import styles from './styles.module.css';

export type NoDataProps = {
  iconName?: IconName;
  text?: string;
  disableIcon?: boolean;
};

const NoData = ({ text = '', iconName = 'MdOutlineCloudOff', disableIcon = false }: NoDataProps) => {
  return (
    <div className={clsx(styles.wrapper, 'd-flex', 'align-items-center', 'flex-column')}>
      {!disableIcon && (
        <div className={styles.icon}>
          <Icon name={iconName} noGutters nonClickable />
        </div>
      )}
      {text && (
        <div className={styles.text}>
          <Title variant="h6">{text}</Title>
        </div>
      )}
    </div>
  );
};

export default memo<NoDataProps>(NoData);
`}
    </HighligherLayout>
  );
};

export default Component;