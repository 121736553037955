import HighligherLayout from '../../../components/HighligherLayout';

const Component = () => {
  return (
    <HighligherLayout label="Usage:">
{`
import { Button } from "@digitalsettings/ui-lib";

const Component = () => {
  return (
    <Button label="Default" iconName="MdSettings" />
  );
};

export default Component;
`}
    </HighligherLayout>
  );
};

export default Component;
