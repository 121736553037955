import HighligherLayout from '../../../components/HighligherLayout';

const Component = () => {
  return (
    <HighligherLayout label="Usage:">
{`
import { useState } from "react";
import { OptionSelect } from "@digitalsettings/ui-lib";

const Component = () => {
  const [selected, setSelected] = useState<boolean>(false);

  return (
    <OptionSelect
      label="Label"
      isSelected={selected}
      onClick={() => {
        setSelected(!selected);
      }}
    />
  );
};

export default Component;
`}
    </HighligherLayout>
  );
};

export default Component;
