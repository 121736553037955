import { ContentLayout, Divider } from "@digitalsettings/ui-lib";
import Space from "../../components/Space";
import Usage from "./Usage";

const Component = () => {
  return (
    <ContentLayout title="Drawer">
      <Divider />

      <Space>
        <Usage />
      </Space>
    </ContentLayout>
  );
};

export default Component;
