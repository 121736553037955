import SyntaxHighlighter from "react-syntax-highlighter";
import { vs2015, stackoverflowLight } from "react-syntax-highlighter/dist/esm/styles/hljs";
import { Title } from '@digitalsettings/ui-lib';

import styles from "./styles.module.css";

type HighligherLayoutProps = {
  label?: string;
  children?: string;
};

const HighligherLayout = ({ label, children }: HighligherLayoutProps) => {
  return (
    <div className={styles.highlighterWrapper}>
      {label && <Title variant="h4" gutterBottomSize="xs">{label}</Title>}
      <SyntaxHighlighter language="typescript" style={stackoverflowLight}>
        {`${children}`}
      </SyntaxHighlighter>
    </div>
  );
};

export default HighligherLayout;
