import HighligherLayout from '../../../components/HighligherLayout';

const Component = () => {
  return (
    <HighligherLayout label="Source:">
{`
import { memo } from 'react';
import { MuiColorInput, MuiColorInputValue, MuiColorInputFormat } from 'mui-color-input';

export type ColorPickerProps = {
  format?: MuiColorInputFormat;
  fallbackValue?: MuiColorInputValue;
  value: MuiColorInputValue;
  onChange?: (newValue: string) => void;
  label?: string;
  error?: boolean;
  helperText?: string;
  fullWidth?: boolean;
  disabled?: boolean;
};

const CustomColorPicker = ({
  format = 'hex',
  fallbackValue,
  value,
  onChange,
  label,
  error,
  helperText,
  fullWidth = true,
  disabled = false,
  ...props
}: ColorPickerProps) => {
  return (
    <MuiColorInput
      format={format}
      fallbackValue={fallbackValue}
      value={value}
      onChange={onChange}
      label={label}
      error={error}
      helperText={helperText}
      fullWidth={fullWidth}
      disabled={disabled}
      {...props}
    />
  );
};

export default memo<ColorPickerProps>(CustomColorPicker);
`}
    </HighligherLayout>
  );
};

export default Component;