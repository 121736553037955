import HighligherLayout from '../../../components/HighligherLayout';

const Component = () => {
  return (
    <HighligherLayout label="Source:">
{`
import { memo } from 'react';
import clsx from 'clsx';
import { IconBaseProps } from 'react-icons';
import Tooltip from '../Tooltip';
import { getIcon, IconName } from './icons';

import styles from './styles.module.css';

export type IconProps = Omit<IconBaseProps, 'onClick'> & {
  label?: string;
  labelPosition?: 'left' | 'right';
  labelBold?: boolean;
  labelUppercase?: boolean;
  name: IconName | '';
  size?: 'small' | 'medium' | 'large';
  color?: 'default' | 'black' | 'white' | 'dark' | 'medium' | 'light' | 'success' | 'error';
  active?: boolean;
  noGutters?: boolean;
  disabled?: boolean;
  hide?: boolean;
  tooltipTitle?: string;
  nonClickable?: boolean;
  badge?: boolean | number;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
};

const Icon = ({
  label,
  labelPosition = 'right',
  labelBold = true,
  labelUppercase = false,
  className,
  name,
  size = 'medium',
  color = 'default',
  active = false,
  noGutters = false,
  disabled = false,
  hide = false,
  tooltipTitle,
  nonClickable = false,
  badge = false,
  onClick,
  ...props
}: IconProps) => {
  const IconComponent = getIcon(name);
  return name !== '' ? (
    <Tooltip title={!label && tooltipTitle}>
      <div
        className={clsx(styles.iconBox, className, {
          [styles.disabled]: disabled,
          [styles.nonClickable]: nonClickable,
          [styles.hide]: hide,
          [styles.colorDefault]: color === 'default',
          [styles.colorBlack]: color === 'black',
          [styles.colorWhite]: color === 'white',
          [styles.colorDark]: color === 'dark',
          [styles.colorMedium]: color === 'medium',
          [styles.colorLight]: color === 'light',
          [styles.colorSuccess]: color === 'success',
          [styles.colorError]: color === 'error',
          [styles.small]: size === 'small',
          [styles.medium]: size === 'medium',
          [styles.large]: size === 'large',
        })}
        onClick={onClick}
      >
        {label && labelPosition === 'left' && (
          <div className={clsx(styles.label, styles.leftLabel, { 'f-bold': labelBold, 'f-upper': labelUppercase })}>
            {label}
          </div>
        )}
        <div className={styles.iconWrap}>
          <IconComponent
            className={clsx(styles.icon, {
              [styles.active]: active,
              [styles.noGutters]: noGutters,
              [styles.disabled]: disabled,
              [styles.nonClickable]: nonClickable,
              [styles.marginRight]: noGutters && label && labelPosition === 'right',
              [styles.marginLeft]: noGutters && label && labelPosition === 'left',
            })}
            {...props}
          />
          {badge && typeof badge === 'boolean' && <div className={styles.badge}></div>}
          {badge && typeof badge === 'number' && <div className={clsx(styles.badgeNumber, 'f-bold')}>{badge}</div>}
        </div>
        {label && labelPosition === 'right' && (
          <div className={clsx(styles.label, styles.rightLabel, { 'f-bold': labelBold, 'f-upper': labelUppercase })}>
            {label}
          </div>
        )}
      </div>
    </Tooltip>
  ) : null;
};

export default memo<IconProps>(Icon);


// Icon Names

Md3DRotation
MdHelp
MdAccessTime
MdAccountTree
MdAdd
MdArrowDownward
MdArrowDropDown
MdArrowUpward
MdAutorenew
MdBusiness
MdCardGiftcard
MdCategory
MdCheck
MdCheckCircle
MdCircle
MdClose
MdColorLens
MdContactPhone
MdCreditCard
MdDelete
MdDetails
MdDns
MdDownload
MdError
MdErrorOutline
MdEuro
MdFemale
MdFilePresent
MdFileUpload
MdFolder
MdGppBad
MdGridView
MdHome
MdHomeFilled
MdImage
MdInfo
MdInsertComment
MdKeyboardArrowUp
MdKeyboardArrowDown
MdKeyboardArrowLeft
MdKeyboardArrowRight
MdLabel
MdLabelImportant
MdLanguage
MdLibraryAddCheck
MdLocalCafe
MdLocalPolice
MdLocationPin
MdLogout
MdMale
MdManageAccounts
MdMap
MdMenu
MdMenuOpen
MdModeEdit
MdMoreVert
MdNoAccounts
MdNorth
MdNotifications
MdNotificationsNone
MdOutlineCloudOff
MdOutlineDarkMode
MdOutlineDoNotDisturbAlt
MdOutlineDragIndicator
MdOutlineFileDownload
MdOutlineLightMode
MdOutlineMenuBook
MdOutlinePause
MdOutlinePlayArrow
MdOutlineSwapVert
MdPassword
MdPeople
MdPerson
MdPhotoCamera
MdPreview
MdPrivacyTip
MdRemove
MdReply
MdRestartAlt
MdSearch
MdSettings
MdSettingsSuggest
MdSort
MdSouth
MdSpaceDashboard
MdStarRate
MdStoreMallDirectory
MdTranslate
MdTune
MdUploadFile
MdVerified
MdViewList
MdVisibility
MdWorkspaces
MdOutlineRadioButtonUnchecked
MdCalendarToday
MdDateRange
MdOutlineCheck
MdRadioButtonChecked
MdRadioButtonUnchecked
MdWarning
MdFactCheck
MdDashboard
MdDynamicFeed
MdHorizontalSplit
MdOutlineMoveDown
MdScreenshotMonitor
MdSnowing
AiFillEye
AiFillEyeInvisible
`}
    </HighligherLayout>
  );
};

export default Component;
