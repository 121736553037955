import HighligherLayout from '../../../components/HighligherLayout';

const Component = () => {
  return (
    <HighligherLayout label="Usage:">
{`
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Drawer, IconName } from "@digitalsettings/ui-lib";

const Component = () => {
  const navigate = useNavigate();
  const [isDrawerOpened, setIsDrawerOpened] = useState(true);
  
  return (
    <Drawer
      isOpened={isDrawerOpened}
      drawerItems={drawerItems}
      onRedirect={(to) => navigate(to)}
      closeDrawer={() => setIsDrawerOpened(false)}
      openDrawer={() => setIsDrawerOpened(true)}
    />
  );
};

export default Component;

type DrawerItem = {
  name: string;
  to?: string;
  iconName?: IconName;
  childItems?: DrawerItem[];
};

const drawerItems: DrawerItem[] = [
  {
    name: "favourites",
    to: "/",
    childItems: [
      {
        name: "Companies",
        to: "/core/companies",
        iconName: "MdLanguage",
      },
      {
        name: "Tags",
        to: "/ecommerce/catalog/tags",
        iconName: "MdLanguage",
      },
    ],
  },
  {
    name: "core",
    to: "",
    childItems: [
      {
        name: "Companies",
        to: "/core/companies",
        iconName: "MdLanguage",
      },
      {
        name: "Departments",
        to: "/core/departments",
        iconName: "MdHome",
      },
    ],
  },
  {
    name: "ecommerce",
    to: "",
    childItems: [
      {
        name: "Catalog",
        to: "/ecommerce/catalog",
        iconName: "MdLanguage",
        childItems: [
          {
            name: "Categories",
            to: "/ecommerce/catalog/categories",
          },
          {
            name: "Tags",
            to: "/ecommerce/catalog/tags",
          },
        ],
      },
      {
        name: "Translations",
        to: "/ecommerce/translations",
        iconName: "MdLanguage",
      },
      {
        name: "Config",
        to: "/ecommerce/config",
        iconName: "MdLanguage",
        childItems: [
          {
            name: "General Config",
            to: "/ecommerce/catalog/general-config",
          },
          {
            name: "Footer Config",
            to: "/ecommerce/catalog/footer-config",
          },
        ],
      },
    ],
  },
];
`}
    </HighligherLayout>
  );
};

export default Component;
