import HighligherLayout from '../../../components/HighligherLayout';

const Component = () => {
  return (
    <HighligherLayout label="Usage:">
{`
import { FileDetailsBox } from "@digitalsettings/ui-lib";

const Component = () => {
  return (
    <FileDetailsBox data={files} />
  );
};

export default Component;

const files = [
  {
    name: "File Name (jpg)",
    url: "https://meamacorestorage.blob.core.windows.net/system-notifications/7200e7bd-d16c-4c66-abfa-43a4b0c00073.jpg",
  },
  {
    name: "File Name (xlsx)",
    url: "https://meamacorestorage.blob.core.windows.net/system-notifications/418af4d2-dec5-4980-b8f4-7452ae50c15b.xlsx",
  }
];
`}
    </HighligherLayout>
  );
};

export default Component;
