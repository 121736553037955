import { useState } from "react";
import { ContentLayout, Title, Divider, ColorPicker } from "@digitalsettings/ui-lib";
import Space from "../../components/Space";
import Source from "./Source";
import Usage from "./Usage";

const Component = () => {
  const [color, setColor] = useState("");
  const onChange = (newValue: string) => { setColor(newValue)};

  return (
    <ContentLayout title="Color Picker">
      <Divider />

      <Space>
        <Title variant="h3" gutterBottomSize="xs">
          Example:
        </Title>
        <div>
          <ColorPicker
            label="Text Color"
            value={color}
            onChange={onChange}
            format="hex"
          />
        </div>
      </Space>

      <Space>
        <Usage />
      </Space>
      <Space>
        <Source />
      </Space>
    </ContentLayout>
  );
};

export default Component;
