import { useState } from "react";
import {
  ContentLayout,
  Title,
  Divider,
  ViewToggler,
  ViewTogglerItem,
} from "@digitalsettings/ui-lib";
import Space from "../../components/Space";
import Source from "./Source";
import Usage from "./Usage";

const Component = () => {
  const [currentView, setCurrentView] = useState("");

  return (
    <ContentLayout title="View Toggler">
      <Divider />

      <Space>
        <Title variant="h3" gutterBottomSize="xs">
          Example:
        </Title>
        <div>
          <ViewToggler
            currentViewName={currentView}
            items={views}
            handleViewSelect={setCurrentView}
          />
          <br />
          {currentView === "TreeView" && <div>TreeView</div>}
          {currentView === "ListView" && <div>ListView</div>}
          {currentView === "GridView" && <div>GridView</div>}
        </div>
      </Space>

      <Space>
        <Usage />
      </Space>
      <Space>
        <Source />
      </Space>
    </ContentLayout>
  );
};

export default Component;

const views: ViewTogglerItem[] = [
  {
    iconName: "MdLanguage",
    viewName: "TreeView",
    isDefault: true,
  },
  {
    iconName: "MdAccountTree",
    viewName: "ListView",
    isDefault: false,
  },
  {
    iconName: "MdSettings",
    viewName: "GridView",
    isDefault: false,
  },
];