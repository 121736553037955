import HighligherLayout from '../../../components/HighligherLayout';

const Component = () => {
  return (
    <HighligherLayout label="Source:">
{`
import { cloneElement, memo, MouseEvent } from 'react';
import MMenu, { MMenuProps } from '../MMenu';
import MenuItem, {  MenuItemProps } from '../MenuItem';
import { useClickEvent } from '../../hooks';

type MItem = MenuItemProps & {
  shouldCloseMenu?: boolean;
};

export type MenuProps = Omit<MMenuProps, 'open'> & {
  component: any;
  items?: MItem[];
};

const Menu = ({ component, items, ...props }: MenuProps) => {
  const [anchorEl, menuActions] = useClickEvent();
  const handleClick = (event: MouseEvent<any>) => {
    menuActions.open(event);
  };

  const handleClose = () => {
    menuActions.close();
  };

  return (
    <div>
      {cloneElement(
        component,
        {
          onClick: (e: MouseEvent<HTMLLIElement>) => {
            component.props.onClick?.();
            handleClick(e);
          },
        },
        null
      )}
      <MMenu anchorEl={anchorEl} open={!!anchorEl} onClose={handleClose} {...props}>
        {items?.map(({ children, shouldCloseMenu = true, hidden, onClick, ...props }, index) => {
          if (hidden) return null;
          const onItemClick = (e: MouseEvent<HTMLLIElement>) => {
            onClick?.(e);
            if (shouldCloseMenu) {
              handleClose();
            }
          };

          return (
            <MenuItem key={index} onClick={onItemClick} {...props}>
              {children}
            </MenuItem>
          );
        })}
      </MMenu>
    </div>
  );
};

export default memo<MenuProps>(Menu);
`}
    </HighligherLayout>
  );
};

export default Component;
