import { ContentLayout, Title, Divider, CollapseBox, Text } from "@digitalsettings/ui-lib";
import Space from "../../components/Space";
import Source from "./Source";
import Usage from "./Usage";

const Component = () => {
  return (
    <ContentLayout title="Collapse Box">
      <Divider />

      <Space>
        <Title variant="h3" gutterBottomSize="xs">
          Example:
        </Title>
        <div>
          <CollapseBox title="Collapse Box Title">
            <Text>
              Lorem ipsum dolor sit amet consectetur, adipisicing elit. Quas
              temporibus autem laborum. Unde rem dolore est mollitia pariatur
              ullam adipisci velit corporis vitae itaque animi quaerat
              distinctio sapiente, magnam necessitatibus. Lorem ipsum dolor sit
              amet consectetur, adipisicing elit. Quas temporibus autem laborum.
              Unde rem dolore est mollitia pariatur ullam adipisci velit
              corporis vitae itaque animi quaerat distinctio sapiente, magnam
              necessitatibus. Lorem ipsum dolor sit amet consectetur,
              adipisicing elit. Quas temporibus autem laborum. Unde rem dolore
              est mollitia pariatur ullam adipisci velit corporis vitae itaque
              animi quaerat distinctio sapiente, magnam necessitatibus. Lorem
              ipsum dolor sit amet consectetur, adipisicing elit. Quas
              temporibus autem laborum. Unde rem dolore est mollitia pariatur
              ullam adipisci velit corporis vitae itaque animi quaerat
              distinctio sapiente, magnam necessitatibus.
            </Text>
          </CollapseBox>
        </div>
      </Space>

      <Space>
        <Usage />
      </Space>
      <Space>
        <Source />
      </Space>
    </ContentLayout>
  );
};

export default Component;
