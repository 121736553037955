import HighligherLayout from '../../../components/HighligherLayout';

const Component = () => {
  return (
    <HighligherLayout label="Source:">
{`
import { memo } from 'react';
import MuiDivider, { DividerProps as MuiDividerProps } from '@mui/material/Divider';
import './style.css';

export type DividerProps = MuiDividerProps;

export const Divider = ({ ...props }: DividerProps) => (
  <MuiDivider {...props} />
);

export default memo<DividerProps>(Divider);
`}
    </HighligherLayout>
  );
};

export default Component;
