import HighligherLayout from '../../../components/HighligherLayout';

const Component = () => {
  return (
    <HighligherLayout label="Source:">
{`
import { memo, ReactNode } from 'react';
import Loader from '../Loader';

export type ComponentLoaderProps = {
  children?: ReactNode;
  isLoading?: boolean;
};

const ComponentLoader = ({ children, isLoading }: ComponentLoaderProps) => {
  if (isLoading) return <Loader />;
  return <>{children}</>;
};

export default memo<ComponentLoaderProps>(ComponentLoader);
`}
    </HighligherLayout>
  );
};

export default Component;
