import { useState } from "react";
import { Routes, Route, Outlet, useNavigate } from "react-router-dom";
import { useDynamicVh, MainLayout, Drawer, IconName, Header, Icon, Menu, Switch, useMediaQuery, useDarkMode, ContentLayout } from "@digitalsettings/ui-lib";

// Layout
import MainLayoutTest from "./pages/MainLayout";
import ContentLayoutTest from "./pages/ContentLayout";
import HeaderTest from "./pages/Header";

// Forms
import Checkbox from "./pages/Checkbox";
import Radio from "./pages/Radio";
import SwitchTest from "./pages/Switch";
import Button from "./pages/Button";
import TextField from "./pages/TextField";
import DatePicker from "./pages/DatePicker";
import DateTimePicker from "./pages/DateTimePicker";
import Select from "./pages/Select";
import Search from "./pages/Search";
import ColorPicker from "./pages/ColorPicker";
import QuillEditor from "./pages/QuillEditor";
import OptionsSelect from "./pages/OptionsSelect";
import QuantitySelector from "./pages/QuantitySelector";

// Data Display
import Chip from "./pages/Chip";
import Divider from "./pages/Divider";
import IconTest from "./pages/Icon";
import Table from "./pages/Table";
import Title from "./pages/Title";
import Text from "./pages/Text";

// Feedback
import Alert from "./pages/Alert";
import Toast from "./pages/Toast";
import Dialog from "./pages/Dialog";
import ComponentLoader from "./pages/ComponentLoader";

// Surfaces
import CollapseBox from "./pages/CollapseBox";

// Navigation
import Breadcrumbs from "./pages/Breadcrumbs";
import DrawerTest from "./pages/Drawer";
import MenuTest from "./pages/Menu";
import Pagination from "./pages/Pagination";
import SpeedDial from "./pages/SpeedDial";
import Tabs from "./pages/Tabs";
import UserMenu from "./pages/UserMenu";

// Other
import MiniImagePreview from "./pages/MiniImagePreview";
import Initials from "./pages/Initials";
import ColorPreview from "./pages/ColorPreview";
import Status from "./pages/Status";
import NoData from "./pages/NoData";
import FileUploader from "./pages/FileUploader";
import ColorSelect from "./pages/ColorSelect";
import ViewToggler from "./pages/ViewToggler";
import FileDetailsBox from "./pages/FileDetailsBox";
import MiniFileUploader from "./pages/MiniFileUploader";
import SchedulePicker from "./pages/SchedulePicker";

type IRoute = {
  to: string;
  label: string;
  component: React.ReactNode
}

const routes: IRoute[] = [
  { to: "/", label: "Home", component: <></> },
  // Layout
  { to: "/components/layout/main-layout", label: "Main Layout", component: <MainLayoutTest /> },
  { to: "/components/layout/content-layout", label: "Content Layout", component: <ContentLayoutTest /> },
  { to: "/components/layout/header", label: "Header", component: <HeaderTest /> },
  // Forms
  { to: "/components/forms/checkbox", label: "Checkbox", component: <Checkbox /> },
  { to: "/components/forms/radio", label: "Radio", component: <Radio /> },
  { to: "/components/forms/switch", label: "Switch", component: <SwitchTest /> },
  { to: "/components/forms/button", label: "Button", component: <Button /> },
  { to: "/components/forms/text-field", label: "Text Field", component: <TextField /> },
  { to: "/components/forms/date-picker", label: "Date Picker", component: <DatePicker /> },
  { to: "/components/forms/date-time-picker", label: "Date Time Picker", component: <DateTimePicker /> },
  { to: "/components/forms/select", label: "Select", component: <Select /> },
  { to: "/components/forms/search", label: "Search", component: <Search /> },
  { to: "/components/forms/color-picker", label: "Color Picker", component: <ColorPicker /> },
  { to: "/components/forms/quill-editor", label: "Quill Editor", component: <QuillEditor /> },
  { to: "/components/forms/options-select", label: "Options Select", component: <OptionsSelect /> },
  { to: "/components/forms/quantity-selector", label: "Quantity Selector", component: <QuantitySelector /> },
  // Data Display
  { to: "/components/data-display/chip", label: "Chip", component: <Chip /> },
  { to: "/components/data-display/divider", label: "Divider", component: <Divider /> },
  { to: "/components/data-display/icon", label: "Icon", component: <IconTest /> },
  { to: "/components/data-display/table", label: "Table", component: <Table /> },
  { to: "/components/data-display/title", label: "Title", component: <Title /> },
  { to: "/components/data-display/text", label: "Text", component: <Text /> },
  // Feedback
  { to: "/components/feedback/alert", label: "Alert", component: <Alert /> },
  { to: "/components/feedback/toast", label: "Toast", component: <Toast /> },
  { to: "/components/feedback/dialog", label: "Dialog", component: <Dialog /> },
  { to: "/components/feedback/component-loader", label: "Component Loader", component: <ComponentLoader /> },
  // Surfaces
  { to: "/components/surfaces/collapse-box", label: "Collapse Box", component: <CollapseBox /> },
  // Navigation
  { to: "/components/navigation/breadcrumbs", label: "Breadcrumbs", component: <Breadcrumbs /> },
  { to: "/components/navigation/drawer", label: "Drawer", component: <DrawerTest /> },
  { to: "/components/navigation/menu", label: "Menu", component: <MenuTest /> },
  { to: "/components/navigation/pagination", label: "Pagination", component: <Pagination /> },
  { to: "/components/navigation/speed-dial", label: "Speed Dial", component: <SpeedDial /> },
  { to: "/components/navigation/tabs", label: "Tabs", component: <Tabs /> },
  { to: "/components/navigation/user-menu", label: "User Menu", component: <UserMenu /> },
  // Other
  { to: "/components/other/mini-image-preview", label: "MiniImagePreview", component: <MiniImagePreview /> },
  { to: "/components/other/initials", label: "Initials", component: <Initials /> },
  { to: "/components/other/color-preview", label: "ColorPreview", component: <ColorPreview /> },
  { to: "/components/other/status", label: "Status", component: <Status /> },
  { to: "/components/other/no-data", label: "No Data", component: <NoData /> },
  { to: "/components/other/file-uploader", label: "File Uploader", component: <FileUploader /> },
  { to: "/components/other/color-select", label: "Color Select", component: <ColorSelect /> },
  { to: "/components/other/view-toggler", label: "View Toggler", component: <ViewToggler /> },
  { to: "/components/other/file-details-box", label: "File Details Box", component: <FileDetailsBox /> },
  { to: "/components/other/mini-file-uploader", label: "Mini File Uploader", component: <MiniFileUploader /> },
  { to: "/components/other/schedule-picker", label: "Schedule Picker", component: <SchedulePicker /> },
];

const App = () => {
  useDynamicVh();

  const navigate = useNavigate();
  const mediaQueryLG = useMediaQuery('lg');
  const [isDrawerOpened, setIsDrawerOpened] = useState(true);
  const { isDarkMode, toggleDarkMode } = useDarkMode();

  return (
    <MainLayout
      isAuthorized
      drawer={
        <Drawer
          isOpened={isDrawerOpened}
          drawerItems={drawerItems}
          onRedirect={(to) => navigate(to)}
          closeDrawer={() => setIsDrawerOpened(false)}
          openDrawer={() => setIsDrawerOpened(true)}
        />
      }
      header={
        <Header
          burgerMenuIcon={
            <Icon
              name="MdMenu"
              color={mediaQueryLG ? "secondary" : "primary"}
              onClick={() => console.log("onBurgerMenuIconClick")}
            />
          }
          logo="logo_url_here"
          logoAltText="Meama"
          onLogoClick={() => console.log("onLogoClick")}
          currentPageName="Digital Settings Ui-Lib Documentation - v0.1.43"
          settings={
            <Menu
              size="large"
              component={
                <Icon
                  name="MdSettings"
                  color={mediaQueryLG ? "secondary" : "primary"}
                />
              }
              items={[
                {
                  shouldCloseMenu: false,
                  onClick: toggleDarkMode,
                  children: (
                    <>
                      <Icon
                        name="MdOutlineDarkMode"
                        label="Dark Theme"
                        nonClickable
                        noGutters
                      />
                      <Switch checked={isDarkMode} noGutters />
                    </>
                  ),
                }
              ]}
            />
          }
        />
      }
    >
      <Routes>
        <Route path="/" element={<Outlet />}>
          {routes.map(({ component: Component, to }) => (
              <Route key={to} path={to} element={Component} />
          ))}
        </Route>
      </Routes>
    </MainLayout>
  );
};

export default App;


type DrawerItem = {
  name: string;
  to?: string;
  iconName?: IconName;
  childItems?: DrawerItem[];
};

const drawerItems: DrawerItem[] = [
  {
    name: "components",
    to: "",
    childItems: [
      {
        name: "Layout",
        to: "",
        iconName: "MdScreenshotMonitor",
        childItems: [
          {
            name: "MainLayout",
            to: "/components/layout/main-layout",
          },
          {
            name: "ContentLayout",
            to: "/components/layout/content-layout",
          },
          {
            name: "Header",
            to: "/components/layout/header",
          }
        ],
      },
      {
        name: "Forms",
        to: "",
        iconName: "MdFactCheck",
        childItems: [
          {
            name: "Checkbox",
            to: "/components/forms/checkbox",
          },
          {
            name: "Radio",
            to: "/components/forms/radio",
          },
          {
            name: "Switch",
            to: "/components/forms/switch",
          },
          {
            name: "Button",
            to: "/components/forms/button",
          },
          {
            name: "TextField",
            to: "/components/forms/text-field",
          },
          {
            name: "DatePicker",
            to: "/components/forms/date-picker",
          },
          {
            name: "DateTimePicker",
            to: "/components/forms/date-time-picker",
          },
          {
            name: "Select",
            to: "/components/forms/select",
          },
          {
            name: "Search",
            to: "/components/forms/Search",
          },
          {
            name: "ColorPicker",
            to: "/components/forms/color-picker",
          },
          {
            name: "QuillEditor",
            to: "/components/forms/quill-editor",
          },
          {
            name: "OptionsSelect",
            to: "/components/forms/options-select",
          },
          {
            name: "QuantitySelector",
            to: "/components/forms/quantity-selector",
          },
        ],
      },
      {
        name: "Data Display",
        to: "",
        iconName: "MdDashboard",
        childItems: [
          {
            name: "Chip",
            to: "/components/data-display/chip",
          },
          {
            name: "Divider",
            to: "/components/data-display/divider",
          },
          {
            name: "Icon",
            to: "/components/data-display/icon",
          },
          {
            name: "Table",
            to: "/components/data-display/table",
          },
          {
            name: "Title",
            to: "/components/data-display/title",
          },
          {
            name: "Text",
            to: "/components/data-display/text",
          }
        ],
      },
      {
        name: "Feedback",
        to: "",
        iconName: "MdDynamicFeed",
        childItems: [
          {
            name: "Alert",
            to: "/components/feedback/alert",
          },
          {
            name: "Toast",
            to: "/components/feedback/toast",
          },
          {
            name: "Dialog",
            to: "/components/feedback/dialog",
          },
          {
            name: "ComponentLoader",
            to: "/components/feedback/component-loader",
          }
        ],
      },
      {
        name: "Surfaces",
        to: "",
        iconName: "MdHorizontalSplit",
        childItems: [
          {
            name: "CollapseBox",
            to: "/components/surfaces/collapse-box",
          }
        ],
      },
      {
        name: "Navigation",
        to: "",
        iconName: "MdOutlineMoveDown",
        childItems: [
          {
            name: "Breadcrumbs",
            to: "/components/navigation/breadcrumbs",
          },
          {
            name: "Drawer",
            to: "/components/navigation/drawer",
          },
          {
            name: "Menu",
            to: "/components/navigation/menu",
          },
          {
            name: "Pagination",
            to: "/components/navigation/pagination",
          },
          {
            name: "SpeedDial",
            to: "/components/navigation/speed-dial",
          },
          {
            name: "Tabs",
            to: "/components/navigation/tabs",
          },
          {
            name: "UserMenu",
            to: "/components/navigation/user-menu",
          }
        ],
      },
      {
        name: "Other",
        to: "",
        iconName: "MdSnowing",
        childItems: [
          {
            name: "MiniImagePreview",
            to: "/components/other/mini-image-preview",
          },
          {
            name: "Initials",
            to: "/components/other/initials",
          },
          {
            name: "ColorPreview",
            to: "/components/other/color-preview",
          },
          {
            name: "Status",
            to: "/components/other/status",
          },
          {
            name: "NoData",
            to: "/components/other/no-data",
          },
          {
            name: "FileUploader",
            to: "/components/other/file-uploader",
          },
          {
            name: "ColorSelect",
            to: "/components/other/color-select",
          },
          {
            name: "ViewToggler",
            to: "/components/other/view-toggler",
          },
          {
            name: "FileDetailsBox",
            to: "/components/other/file-details-box",
          },
          {
            name: "MiniFileUploader",
            to: "/components/other/mini-file-uploader",
          },
          {
            name: "SchedulePicker",
            to: "/components/other/schedule-picker",
          }
        ],
      }
    ],
  },
];