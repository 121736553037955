import { ContentLayout, Title, Divider, FileDetailsBox } from "@digitalsettings/ui-lib";
import Space from "../../components/Space";
import Source from "./Source";
import Usage from "./Usage";

const Component = () => {
  return (
    <ContentLayout title="File Details Box">
      <Divider />

      <Space>
        <Title variant="h3" gutterBottomSize="xs">
          Example:
        </Title>
        <div>
          <FileDetailsBox data={files} />
        </div>
      </Space>

      <Space>
        <Usage />
      </Space>
      <Space>
        <Source />
      </Space>
    </ContentLayout>
  );
};

export default Component;

const files = [
  {
    name: "File Name (jpg)",
    url: "https://meamacorestorage.blob.core.windows.net/system-notifications/7200e7bd-d16c-4c66-abfa-43a4b0c00073.jpg",
  },
  {
    name: "File Name (xlsx)",
    url: "https://meamacorestorage.blob.core.windows.net/system-notifications/418af4d2-dec5-4980-b8f4-7452ae50c15b.xlsx",
  }
];
