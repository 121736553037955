import HighligherLayout from '../../../components/HighligherLayout';

const Component = () => {
  return (
    <HighligherLayout label="Usage:">
{`
import { useState } from 'react';
import { Title, Divider, Switch, Header, Menu, Icon, useMediaQuery, UserMenu } from '@digitalsettings/ui-lib';

const Component = () => {
  const mediaQueryLG = useMediaQuery('lg');
  const [isDarkTheme, setIsDarkTheme] = useState(false);
  
  return (
    <Header
      burgerMenuIcon={
        <Icon
          name="MdMenu"
          color={mediaQueryLG ? "white" : "default"}
          onClick={() => console.log("onBurgerMenuIconClick")}
        />
      }
      logo="logo_url_here"
      logoAltText="Meama"
      onLogoClick={() => console.log("onLogoClick")}
      currentPageName="Ecommerce"
      breadcrumbs={[
        {
          name: "Home",
          to: "/",
          isValidUrl: true,
        },
        {
          name: "Ecommerce",
          to: "/",
          isValidUrl: true,
        },
        {
          name: "Catalog",
          to: "/",
          isValidUrl: false,
        },
        {
          name: "Add Or Update",
          to: "/",
          isValidUrl: false,
        },
      ]}
      search={
        <Menu
          size="large"
          component={
            <Icon
              name="MdSearch"
              color={mediaQueryLG ? "white" : "default"}
            />
          }
        />
      }
      settings={
        <Menu
          size="large"
          component={
            <Icon
              name="MdSettings"
              color={mediaQueryLG ? "white" : "default"}
            />
          }
          items={[
            {
              shouldCloseMenu: false,
              onClick: () => setIsDarkTheme(!isDarkTheme),
              children: (
                <>
                  <Icon
                    name="MdOutlineDarkMode"
                    label="Dark Theme"
                    nonClickable
                    noGutters
                    color="black"
                  />
                  <Switch checked={isDarkTheme} noGutters />
                </>
              ),
            },
            {
              children: (
                <Icon
                  name="MdSpaceDashboard"
                  label="Menu Parameters"
                  nonClickable
                  noGutters
                  color="black"
                />
              ),
              onClick: () => console.log("Go To Menu Parameters"),
            },
          ]}
        />
      }
      languages={
        <Menu
          size="large"
          component={
            <Icon
              name="MdLanguage"
              color={mediaQueryLG ? "white" : "default"}
            />
          }
          items={[
            {
              children: (
                <Icon
                  name="MdRadioButtonUnchecked"
                  label="EN English"
                  nonClickable
                  noGutters
                  color="black"
                />
              ),
              onClick: () => console.log("English"),
            },
            {
              children: (
                <Icon
                  name="MdRadioButtonChecked"
                  label="KA ქართული"
                  nonClickable
                  noGutters
                  color="black"
                />
              ),
              onClick: () => console.log("ქართული"),
            },
          ]}
        />
      }
      userMenu={
        <UserMenu
          name="David Savaneli"
          email="savaneli.david@gmail.com"
          showBadge={true}
          onClick={() => console.log("open menu")}
          menuItems={[
            {
              children: (
                <Icon
                  name="MdNotifications"
                  label="Notifications"
                  nonClickable
                  noGutters
                  color="black"
                  badge={2}
                />
              ),
            },
            {
              children: (
                <Icon
                  name="MdPassword"
                  label="Change Password"
                  nonClickable
                  noGutters
                  color="black"
                />
              ),
            },
            {
              children: (
                <Icon
                  name="MdManageAccounts"
                  label="Profile"
                  nonClickable
                  noGutters
                  color="black"
                />
              ),
            },
            {
              children: (
                <Icon
                  name="MdLogout"
                  label="Logout"
                  nonClickable
                  noGutters
                  color="black"
                />
              ),
            },
          ]}
        />
      }
    />
  );
};

export default Component;
`}
    </HighligherLayout>
  );
};

export default Component;
