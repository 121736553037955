import HighligherLayout from '../../../components/HighligherLayout';

const Component = () => {
  return (
    <HighligherLayout label="Source:">
{`
import { ReactNode, memo } from 'react';
import clsx from 'clsx';
import styles from './styles.module.css';

export type TitleProps = {
  variant?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | undefined;
  gutterTopSize?: 'xl' | 'lg' | 'md' | 'sm' | 'xs';
  gutterBottomSize?: 'xl' | 'lg' | 'md' | 'sm' | 'xs';
  gutterSize?: 'xl' | 'lg' | 'md' | 'sm' | 'xs';
  color?: 'default' | 'black' | 'white' | 'dark' | 'medium' | 'light' | 'success' | 'error';
  bold?: boolean;
  uppercase?: boolean;
  children?: ReactNode;
  subTitle?: string;
};

const Title = ({
  variant = 'h2',
  gutterTopSize,
  gutterBottomSize,
  gutterSize,
  color = 'default',
  bold = false,
  uppercase = false,
  children,
  subTitle,
  ...props
}: TitleProps) => {
  return (
    <div
      className={clsx(styles.titleBox, {
        [styles.gutterTopSizeXl]: gutterTopSize === 'xl',
        [styles.gutterTopSizeLg]: gutterTopSize === 'lg',
        [styles.gutterTopSizeMd]: gutterTopSize === 'md',
        [styles.gutterTopSizeSm]: gutterTopSize === 'sm',
        [styles.gutterTopSizeXs]: gutterTopSize === 'xs',
        [styles.gutterBottomSizeXl]: gutterBottomSize === 'xl',
        [styles.gutterBottomSizeLg]: gutterBottomSize === 'lg',
        [styles.gutterBottomSizeMd]: gutterBottomSize === 'md',
        [styles.gutterBottomSizeSm]: gutterBottomSize === 'sm',
        [styles.gutterBottomSizeXs]: gutterBottomSize === 'xs',
        [styles.gutterSizeXl]: gutterSize === 'xl',
        [styles.gutterSizeLg]: gutterSize === 'lg',
        [styles.gutterSizeMd]: gutterSize === 'md',
        [styles.gutterSizeSm]: gutterSize === 'sm',
        [styles.gutterSizeXs]: gutterSize === 'xs',
        [styles.colorDefault]: color === 'default',
        [styles.colorBlack]: color === 'black',
        [styles.colorWhite]: color === 'white',
        [styles.colorDark]: color === 'dark',
        [styles.colorMedium]: color === 'medium',
        [styles.colorLight]: color === 'light',
        [styles.colorSuccess]: color === 'success',
        [styles.colorError]: color === 'error',
      })}
      {...props}
    >
      {children && variant === 'h1' && (
        <h1 className={clsx(styles.title, { 'f-bold': bold, 'f-upper': uppercase })}>
          {children} {subTitle && <span className={clsx(styles.subTitle, 'f-normal')}>{subTitle}</span>}
        </h1>
      )}
      {children && variant === 'h2' && (
        <h2 className={clsx(styles.title, { 'f-bold': bold, 'f-upper': uppercase })}>
          {children} {subTitle && <span className={clsx(styles.subTitle, 'f-normal')}>{subTitle}</span>}
        </h2>
      )}
      {children && variant === 'h3' && (
        <h3 className={clsx(styles.title, { 'f-bold': bold, 'f-upper': uppercase })}>
          {children} {subTitle && <span className={clsx(styles.subTitle, 'f-normal')}>{subTitle}</span>}
        </h3>
      )}
      {children && variant === 'h4' && (
        <h4 className={clsx(styles.title, { 'f-bold': bold, 'f-upper': uppercase })}>
          {children} {subTitle && <span className={clsx(styles.subTitle, 'f-normal')}>{subTitle}</span>}
        </h4>
      )}
      {children && variant === 'h5' && (
        <h5 className={clsx(styles.title, { 'f-bold': bold, 'f-upper': uppercase })}>
          {children} {subTitle && <span className={clsx(styles.subTitle, 'f-normal')}>{subTitle}</span>}
        </h5>
      )}
      {children && variant === 'h6' && (
        <h6 className={clsx(styles.title, { 'f-bold': bold, 'f-upper': uppercase })}>
          {children} {subTitle && <span className={clsx(styles.subTitle, 'f-normal')}>{subTitle}</span>}
        </h6>
      )}
    </div>
  );
};

export default memo<TitleProps>(Title);
`}
    </HighligherLayout>
  );
};

export default Component;
