import HighligherLayout from '../../../components/HighligherLayout';

const Component = () => {
  return (
    <HighligherLayout label="Usage:">
{`
import { FileUploader } from "@digitalsettings/ui-lib";

const Component = () => {
  return (
    <FileUploader
      title="Upload Images"
      subTitle="Recommended Size: 1200x960px"
      urls={[]}
      isMultiple
    />
  );
};

export default Component;
`}
    </HighligherLayout>
  );
};

export default Component;
