import { ContentLayout, Title, Divider, DatePicker } from '@digitalsettings/ui-lib';

const Component = () => {
  return (
    <ContentLayout title="Date Picker">
      <Divider />
      <br />

      <div className="row">
        <div className="col-5">
          <Title variant="h4" gutterSize="sm">
            Default
          </Title>
          <DatePicker label="Date Picker" value={null} onChange={() => {}} />
          <DatePicker label="Date Picker Error" error value={null} onChange={() => {}} />
          <DatePicker
            label="Date Picker Error Helper Text"
            error
            helperText="Helper text error"
            value={null}
            onChange={() => {}}
          />
          <Title variant="h4" gutterSize="sm">
            Filled
          </Title>
          <DatePicker label="Date Picker Value" value={Date.now()} onChange={() => {}} />
          <DatePicker label="Date Picker Value Error" error value={Date.now()} onChange={() => {}} />
          <DatePicker
            label="Date Picker Value Error Helper Text"
            error
            helperText="Helper text error"
            value={Date.now()}
            onChange={() => {}}
          />
          <Title variant="h4" gutterSize="sm">
            Default Disabled
          </Title>
          <DatePicker label="Date Picker" value={null} onChange={() => {}} disabled />
          <DatePicker label="Date Picker Error" error value={null} onChange={() => {}} disabled />
          <DatePicker
            label="Date Picker Error Helper Text"
            error
            helperText="Helper text error"
            value={null}
            onChange={() => {}}
            disabled
          />
          <Title variant="h4" gutterSize="sm">
            Filled Disabled
          </Title>
          <DatePicker label="Date Picker Value" value={Date.now()} onChange={() => {}} disabled />
          <DatePicker label="Date Picker Value Error" error value={Date.now()} onChange={() => {}} disabled />
          <DatePicker
            label="Date Picker Value Error Helper Text"
            error
            helperText="Helper text error"
            value={Date.now()}
            onChange={() => {}}
            disabled
          />
        </div>
        <div className="col-5">
          <Title variant="h4" gutterSize="sm">
            Default
          </Title>
          <DatePicker value={null} onChange={() => {}} />
          <DatePicker error value={null} onChange={() => {}} />
          <DatePicker error helperText="Helper text error" value={null} onChange={() => {}} />
          <Title variant="h4" gutterSize="sm">
            Filled
          </Title>
          <DatePicker value={Date.now()} onChange={() => {}} />
          <DatePicker error value={Date.now()} onChange={() => {}} />
          <DatePicker error helperText="Helper text error" value={Date.now()} onChange={() => {}} />
          <Title variant="h4" gutterSize="sm">
            Default Disabled
          </Title>
          <DatePicker value={null} onChange={() => {}} disabled />
          <DatePicker error value={null} onChange={() => {}} disabled />
          <DatePicker error helperText="Helper text error" value={null} onChange={() => {}} disabled />
          <Title variant="h4" gutterSize="sm">
            Filled Disabled
          </Title>
          <DatePicker value={Date.now()} onChange={() => {}} disabled />
          <DatePicker error value={Date.now()} onChange={() => {}} disabled />
          <DatePicker error helperText="Helper text error" value={Date.now()} onChange={() => {}} disabled />
        </div>
      </div>
    </ContentLayout>
  );
};

export default Component;
