import HighligherLayout from '../../../components/HighligherLayout';

const Component = () => {
  return (
    <HighligherLayout label="Usage:">
{`
import { MainLayout } from "@digitalsettings/ui-lib";

const Component = () => {
  return (
    <MainLayout
      isAuthorized
      drawer={
        // Drawer Component
      }
      header={
        // Header Component
      }
    >
      // Content Layout ...
    </MainLayout>
  );
};

export default Component;
`}
    </HighligherLayout>
  );
};

export default Component;
