import HighligherLayout from '../../../components/HighligherLayout';

const Component = () => {
  return (
    <HighligherLayout label="Usage:">
{`
import { UserMenu, Icon } from "@digitalsettings/ui-lib";

const Component = () => {
  return (
    <UserMenu
      name="David Savaneli"
      email="savaneli.david@gmail.com"
      showBadge={true}
      onClick={() => console.log("open menu")}
      menuItems={[
        {
          children: (
            <Icon name="MdNotifications" label="Notifications" nonClickable noGutters color="black" badge={2} />
          ),
        },
        {
          children: (
            <Icon name="MdPassword" label="Change Password" nonClickable noGutters color="black" />
          ),
        },
        {
          children: (
            <Icon name="MdManageAccounts" label="Profile" nonClickable noGutters color="black" />
          ),
        },
        {
          children: (
            <Icon name="MdLogout" label="Logout" nonClickable noGutters color="black" />
          ),
        },
      ]}
    />
  );
};

export default Component;
`}
    </HighligherLayout>
  );
};

export default Component;
