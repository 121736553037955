import HighligherLayout from '../../../components/HighligherLayout';

const Component = () => {
  return (
    <HighligherLayout label="Usage:">
{`
import { MiniImagePreview } from "@digitalsettings/ui-lib";

const Component = () => {
  return (
    <MiniImagePreview
      imgUrl="image_url_here"
      imgAltText="Alt Text"
    />
  );
};

export default Component;
`}
    </HighligherLayout>
  );
};

export default Component;
