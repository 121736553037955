import { ContentLayout, Divider, SpeedDial, Title } from "@digitalsettings/ui-lib";
import Space from "../../components/Space";
import Source from "./Source";
import Usage from "./Usage";

const Component = () => {
  return (
    <ContentLayout title="Speed Dial">
      <Divider />

      <Space>
        <Title variant="h3" gutterBottomSize="xs">
          Example:
        </Title>
        <div>
          <SpeedDial
            actions={[
              { iconName: "MdAdd", label: "Add New", onClick: () => {} },
              { iconName: "MdDelete", label: "Delete", onClick: () => {} },
            ]}
          />
        </div>
      </Space>

      <Space>
        <Usage />
      </Space>
      <Space>
        <Source />
      </Space>
    </ContentLayout>
  );
};

export default Component;
