import { ContentLayout, Title, Divider, ColorSelect } from "@digitalsettings/ui-lib";
import Space from "../../components/Space";
import Source from "./Source";
import Usage from "./Usage";

const Component = () => {
  return (
    <ContentLayout title="Color Select">
      <Divider />

      <Space>
        <Title variant="h3" gutterBottomSize="xs">
          Example:
        </Title>
        <div>
          <ColorSelect
            colors={colors}
            isRequired
            onChange={(colorId) => {
              console.log(colorId);
            }}
          />
        </div>
      </Space>

      <Space>
        <Usage />
      </Space>
      <Space>
        <Source />
      </Space>
    </ContentLayout>
  );
};

export default Component;

const colors = [
  {
    id: 1,
    color: "#C93E3E",
    isSelected: false,
  },
  {
    id: 2,
    color: "#FF7BFF",
    isSelected: false,
  },
  {
    id: 3,
    color: "#4E7F87",
    isSelected: false,
  },
  {
    id: 4,
    color: "#F5CB7A",
    isSelected: false,
  },
  {
    id: 5,
    color: "#69B2B6",
    isSelected: false,
  },
  {
    id: 6,
    color: "#877f4e",
    isSelected: false,
  },
  {
    id: 7,
    color: "#dbad50",
    isSelected: false,
  },
  {
    id: 8,
    color: "#888e8f",
    isSelected: false,
  },
];
