import HighligherLayout from '../../../components/HighligherLayout';

const Component = () => {
  return (
    <HighligherLayout label="Usage:">
{`
import { ChangeEvent, useState } from "react";
import { Search } from "@digitalsettings/ui-lib";

const Component = () => {
  const [suggestions, setSuggestions] = useState(testSuggestions);

  const handleSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSuggestions(
      testSuggestions.filter(({ label }) =>
        label.includes(e.target.value.toLowerCase())
      )
    );
  };
  
  return (
    <Search
      hasSuggestions
      minLettersToShowSuggestions={3}
      suggestions={suggestions}
      onChange={handleSearchChange}
    />
  );
};

export default Component;
`}
    </HighligherLayout>
  );
};

export default Component;

const testSuggestions = [
  { label: "Test label 1", value: "val 1" },
  { label: "Test label 2", value: "val 2" },
  { label: "Test label 3", value: "val 3" },
];
