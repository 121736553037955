import HighligherLayout from '../../../components/HighligherLayout';

const Component = () => {
  return (
    <HighligherLayout label="Usage:">
{`
import { Switch, Menu, Icon, Button } from "@digitalsettings/ui-lib";

const Component = () => {
  return (
    <Menu
      size="large"
      component={
        <Button label="Dropdown Menu" variant="dropdown" />
      }
      items={[
        {
          shouldCloseMenu: false,
          onClick: () => setIsDarkTheme(!isDarkTheme),
          children: (
            <>
              <Icon
                name="MdOutlineDarkMode"
                label="Dark Theme"
                nonClickable
                noGutters
                color="black"
              />
              <Switch checked={isDarkTheme} noGutters />
            </>
          ),
        },
        {
          children: (
            <Icon
              name="MdSpaceDashboard"
              label="Menu Parameters"
              nonClickable
              noGutters
              color="black"
            />
          ),
          onClick: () => console.log("Go To Menu Parameters"),
        },
      ]}
    />
  );
};

export default Component;
`}
    </HighligherLayout>
  );
};

export default Component;
