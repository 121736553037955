import HighligherLayout from '../../../components/HighligherLayout';

const Component = () => {
  return (
    <HighligherLayout label="Usage:">
{`
import { QuillEditor } from "@digitalsettings/ui-lib";

const Component = () => {
  return (
    <QuillEditor
      size="small"
      quillId="1"
      value=""
      placeholder="Description"
      onChange={(o: any) => console.log(o)}
    />
  );
};

export default Component;
`}
    </HighligherLayout>
  );
};

export default Component;
