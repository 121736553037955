import HighligherLayout from '../../../components/HighligherLayout';

const Component = () => {
  return (
    <HighligherLayout label="Source:">
{`
import { memo } from 'react';
import clsx from 'clsx';
import Tooltip from '../Tooltip';
import Icon from '../Icon';
import { IconName } from '../Icon/icons';
import styles from './styles.module.css';

export type MiniImagePreviewProps = {
  size?: 'small' | 'medium' | 'large';
  rounded?: boolean;
  imgUrl?: string;
  imgAltText?: string;
  iconName?: IconName;
  hasOnlineStatus?: boolean;
  isOnline?: boolean;
};

const MiniImagePreview = ({
  size = 'medium',
  rounded = true,
  imgUrl,
  imgAltText = '',
  iconName = 'MdImage',
  hasOnlineStatus = false,
  isOnline = false,
}: MiniImagePreviewProps) => {
  return (
    <Tooltip title={imgAltText}>
      <div
        className={clsx(styles.box, {
          [styles.small]: size === 'small',
          [styles.medium]: size === 'medium',
          [styles.large]: size === 'large',
          [styles.rounded]: rounded,
        })}
      >
        {hasOnlineStatus && (
          <div
            className={clsx(styles.onlineStatus, {
              [styles.online]: isOnline,
              [styles.offline]: !isOnline,
            })}
          ></div>
        )}
        {imgUrl ? (
          <img src={imgUrl} alt={imgAltText} className={styles.image} />
        ) : (
          <Icon name={iconName} noGutters nonClickable className={styles.icon} color="black" />
        )}
      </div>
    </Tooltip>
  );
};

export default memo<MiniImagePreviewProps>(MiniImagePreview);
`}
    </HighligherLayout>
  );
};

export default Component;