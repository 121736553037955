import { ContentLayout, Title, Divider, QuillEditor } from "@digitalsettings/ui-lib";
import Space from "../../components/Space";
import Usage from "./Usage";

const Component = () => {
  return (
    <ContentLayout title="Quill Editor">
      <Divider />

      <Space>
        <Title variant="h3" gutterBottomSize="xs">
          Example:
        </Title>
        <div>
          <QuillEditor
            size="small"
            quillId="2"
            value=""
            placeholder="Description"
            onChange={(o: any) => console.log(o)}
          />
        </div>
      </Space>

      <Space>
        <Usage />
      </Space>
    </ContentLayout>
  );
};

export default Component;
