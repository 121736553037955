import { ReactNode } from "react";
import styles from "./styles.module.css";

type SpaceProps = {
  children?: ReactNode;
};

const Space = ({ children }: SpaceProps) => {
  return (
    <div className={styles.space}>
      {children}
    </div>
  );
};

export default Space;
