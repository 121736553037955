import HighligherLayout from '../../../components/HighligherLayout';

const Component = () => {
  return (
    <HighligherLayout label="Usage:">
{`
import React, { useState } from "react";
import { TabContext, TabList, Tab, TabPanel } from "@digitalsettings/ui-lib";

const Component = () => {
  const [value, setValue] = useState("1");
  const handleChange = (event: React.SyntheticEvent, newValue: string) => setValue(newValue);

  return (
    <TabContext value={value}>
      <TabList onChange={handleChange}>
        <Tab
          label="Georgian"
          value="1"
          imgUrl="img_url_here"
        />
        <Tab
          label="English"
          disabled
          value="2"
          imgUrl="img_url_here"
        />
        <Tab
          label="German"
          value="3"
          imgUrl="img_url_here"
        />
        <Tab
          label="Ukraine"
          value="4"
          imgUrl="img_url_here"
        />
      </TabList>
      <TabPanel value="1">Georgian Content ...</TabPanel>
      <TabPanel value="2">English Content ...</TabPanel>
      <TabPanel value="3">German Content ...</TabPanel>
      <TabPanel value="4">Ukraine Content ...</TabPanel>
    </TabContext>
  );
};

export default Component;
`}
    </HighligherLayout>
  );
};

export default Component;
