import HighligherLayout from '../../../components/HighligherLayout';

const Component = () => {
  return (
    <HighligherLayout label="Source:">
{`
import { memo } from 'react';
import MuiPagination, { PaginationProps as MuiPaginationProps } from '@mui/material/Pagination';
import './style.css';

export type PaginationProps = MuiPaginationProps;

export const Pagination = ({ ...props }: PaginationProps) => (
  <MuiPagination {...props} />
);

export default memo<PaginationProps>(Pagination);
`}
    </HighligherLayout>
  );
};

export default Component;
