import HighligherLayout from '../../../components/HighligherLayout';

const Component = () => {
  return (
    <HighligherLayout label="Usage:">
{`
import {
  Dialog,
  Tab,
  TabList,
  TabPanel,
  Button,
  TabContext
} from "@digitalsettings/ui-lib";

const Component = () => {
  const [open, setOpen] = useState(false);
  const handleClickOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [value, setValue] = useState("1");
  const handleChange = (event: React.SyntheticEvent, newValue: string) => setValue(newValue);

  return (
    <>
      <Button
        label="Dialog (With Tabs)"
        onClick={() => handleClickOpen()}
      />
      <TabContext value={value}>
        <Dialog
          open={open}
          onClose={handleClose}
          title="Dialog Title"
          stickyContent={
            <TabList onChange={handleChange}>
              <Tab
                label="Georgian"
                value="1"
                imgUrl="img_url_here"
              />
              <Tab
                label="English"
                value="2"
                imgUrl="img_url_here"
              />
              <Tab
                label="German"
                value="3"
                imgUrl="img_url_here"
              />
              <Tab
                label="Ukraine"
                value="4"
                imgUrl="img_url_here"
              />
            </TabList>
          }
          content={
            <div>
              <TabPanel value="1">Georgian Content ...</TabPanel>
              <TabPanel value="2">English Content ...</TabPanel>
              <TabPanel value="3">German Content ...</TabPanel>
              <TabPanel value="4">Ukraine Content ...</TabPanel>
            </div>
          }
          actions={
            <>
              <Button label="Cancel" variant="text" rounded />
              <Button label="Save" variant="faded" rounded />
            </>
          }
        />
      </TabContext>
    </>
  );
};

export default Component;
`}
    </HighligherLayout>
  );
};

export default Component;
