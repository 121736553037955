import { ContentLayout, Title, Divider, Status } from "@digitalsettings/ui-lib";
import Space from "../../components/Space";
import Source from "./Source";
import Usage from "./Usage";

const Component = () => {
  return (
    <ContentLayout title="Status">
      <Divider />

      <Space>
        <Title variant="h3" gutterBottomSize="xs">
          Example:
        </Title>
        <div>
          <Status
            statuses={{
              success: "success",
              error: "error",
              warning: "warning",
              info: "info",
            }}
            status="success"
          />
        </div>
      </Space>

      <Space>
        <Usage />
      </Space>
      <Space>
        <Source />
      </Space>
    </ContentLayout>
  );
};

export default Component;
