import HighligherLayout from '../../../components/HighligherLayout';

const Component = () => {
  return (
    <HighligherLayout label="Source:">
{`
import { memo } from 'react';
import clsx from 'clsx';
import MuiSwitch, { SwitchProps as MuiSwitchProps } from '@mui/material/Switch';
import FormControlLabel from '../FormControlLabel';
import './style.css';

export type SwitchProps = MuiSwitchProps & {
  label?: string;
  noGutters?: boolean;
};

export const Switch = ({ label, noGutters = false, ...props }: SwitchProps) => (
  <FormControlLabel className={clsx({ 'no-gutters': noGutters })} control={<MuiSwitch {...props} />} label={label} />
);

export default memo<SwitchProps>(Switch);
`}
    </HighligherLayout>
  );
};

export default Component;
