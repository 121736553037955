import HighligherLayout from '../../../components/HighligherLayout';

const Component = () => {
  return (
    <HighligherLayout label="Source:">
{`
import { memo, ReactNode } from 'react';
import clsx from 'clsx';
import { useMediaQuery } from '../../hooks';
import Title from '../Title';
import styles from './styles.module.css';
import { Breadcrumbs } from '../index';
import { IBreadCrumb } from '../../types/common';

export type HeaderProps = {
  burgerMenuIcon?: ReactNode;
  logo?: any;
  logoAltText?: string;
  onLogoClick?: () => void;
  currentPageName?: string;
  breadcrumbs?: IBreadCrumb[];
  search?: ReactNode;
  settings?: ReactNode;
  languages?: ReactNode;
  userMenu?: ReactNode;
};

const Header = ({
  burgerMenuIcon,
  logo,
  logoAltText = 'Logo',
  onLogoClick,
  currentPageName,
  breadcrumbs,
  search,
  settings,
  languages,
  userMenu,
}: HeaderProps) => {
  const mediaQueryLG = useMediaQuery('lg');

  return (
    <div className={styles.header}>
      <div className={styles.wrapper}>
        <div className="d-flex flex-column">
          <div className={styles.respLeftSide}>
            {mediaQueryLG && burgerMenuIcon && <div className={styles.burgerIcon}>{burgerMenuIcon}</div>}
            {mediaQueryLG && logo && (
              <div className={styles.logo}>
                <img src={logo} alt={logoAltText} onClick={onLogoClick} />
              </div>
            )}
          </div>
          {!mediaQueryLG && currentPageName && (
            <div className={styles.pageTitle}>
              <Title variant="h6">{currentPageName}</Title>
            </div>
          )}
          {!mediaQueryLG && breadcrumbs && (
            <div className={styles.breadcrumbs}>
              <Breadcrumbs breadcrumbs={breadcrumbs} />
            </div>
          )}
        </div>
        {(search || settings || languages || userMenu) && (
          <div className="d-flex align-items-center">
            {search && <div className={styles.menuIcon}>{search}</div>}
            {settings && <div className={styles.menuIcon}>{settings}</div>}
            {languages && <div className={styles.menuIcon}>{languages}</div>}
            {userMenu && <div className={clsx(styles.menuIcon, styles.userMenu)}>{userMenu}</div>}
          </div>
        )}
      </div>
    </div>
  );
};

export default memo<HeaderProps>(Header);
`}
    </HighligherLayout>
  );
};

export default Component;
