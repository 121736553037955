import HighligherLayout from '../../../components/HighligherLayout';

const Component = () => {
  return (
    <HighligherLayout label="Source:">
{`
import React, { memo } from 'react';

import MSpeedDial from '../MSpeedDial';
import MSpeedDialIcon from '../MSpeedDialIcon';
import MSpeedDialAction from '../MSpeedDialAction';
import Icon from '../Icon';
import { IconName } from '../Icon/icons';
import { useMediaQuery } from '../../hooks';

export type SpeedDialProps = {
  actions: { label: string; iconName: IconName; onClick: (e: React.MouseEvent<HTMLDivElement>) => void }[];
};

const CustomSpeedDial = ({ actions }: SpeedDialProps) => {
  const isSingleAction = actions.length === 1;
  const isMd = useMediaQuery('md');

  if (isSingleAction) {
    const { iconName, onClick } = actions[0];

    return (
      <MSpeedDial
        ariaLabel="speedDialButtons"
        onClick={onClick}
        open={false}
        icon={<Icon name={iconName} color="white" nonClickable size="large" />}
      />
    );
  }

  return (
    <MSpeedDial ariaLabel="speedDialButtons" icon={<MSpeedDialIcon />}>
      {actions.map(({ iconName, label, onClick }) => (
        <MSpeedDialAction
          key={iconName}
          icon={<Icon name={iconName} noGutters color="white" nonClickable />}
          tooltipOpen={isMd}
          tooltipTitle={label}
          onClick={onClick}
        />
      ))}
    </MSpeedDial>
  );
};

export default memo<SpeedDialProps>(CustomSpeedDial);
`}
    </HighligherLayout>
  );
};

export default Component;
