import HighligherLayout from '../../../components/HighligherLayout';

const Component = () => {
  return (
    <HighligherLayout label="Source:">
{`
import { memo } from 'react';
import Icon from '../Icon';

import styles from './styles.module.css';

export type FileDetailsBoxProps = {
  data: { url: string; name: string }[];
};

const imageExtensions = ['jpg', 'jpeg', 'png', 'gif', 'webp', 'avif', 'svg'];

const hasImgExtension = (name: string) => {
  return imageExtensions.some((ext) => name.includes(ext));
};

const FileDetailsBox = ({ data, ...props }: FileDetailsBoxProps) => {
  return (
    <div className={styles.wrapper} {...props}>
      {data.map(({ name, url }) => (
        <div key={name} className={styles.fileBox}>
          <Icon
            className={styles.icon}
            size="large"
            color="dark"
            noGutters
            nonClickable
            name={hasImgExtension(name) ? 'MdPhotoCamera' : 'MdFilePresent'}
          />
          <div className={styles.text}>{name}</div>
          <a className="d-flex align-items-center" href={url} download>
            <Icon active color="dark" size="small" name="MdOutlineFileDownload" />
          </a>
        </div>
      ))}
    </div>
  );
};

export default memo<FileDetailsBoxProps>(FileDetailsBox);
`}
    </HighligherLayout>
  );
};

export default Component;
