import {
  ContentLayout,
  Title,
  Divider,
  Checkbox,
  FormGroup,
  FormLabel,
} from "@digitalsettings/ui-lib";
import Space from "../../components/Space";
import Source from "./Source";
import Usage from "./Usage";

const Component = () => {
  return (
    <ContentLayout title="Checkbox">
      <Divider />

      <Space>
        <Title variant="h3" gutterBottomSize="xs">
          Example:
        </Title>
        <div>
          <FormLabel>Form Label</FormLabel>
          <FormGroup row>
            <Checkbox label="Primary Checkbox" value="primary-checkbox" />
            <Checkbox label="Secondary Checkbox" value="secondary-checkbox" />
          </FormGroup>
        </div>
      </Space>

      <Space>
        <Usage />
      </Space>
      <Space>
        <Source />
      </Space>
    </ContentLayout>
  );
};

export default Component;
