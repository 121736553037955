import {
  ContentLayout,
  Title,
  Divider,
  Radio,
  RadioGroup,
  FormLabel,
} from "@digitalsettings/ui-lib";
import Space from "../../components/Space";
import Source from "./Source";
import Usage from "./Usage";

const Component = () => {
  return (
    <ContentLayout title="Radio">
      <Divider />

      <Space>
        <Title variant="h3" gutterBottomSize="xs">
          Example:
        </Title>
        <div>
          <FormLabel>Form Label</FormLabel>
          <RadioGroup defaultValue="primary-radio" name="radio-buttons-group" row>
            <Radio label="Primary Radio" value="primary-radio" />
            <Radio label="Secondary Radio" value="secondary-radio" />
          </RadioGroup>
        </div>
      </Space>

      <Space>
        <Usage />
      </Space>
      <Space>
        <Source />
      </Space>
    </ContentLayout>
  );
};

export default Component;
