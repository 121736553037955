import React, { useState } from 'react';
import { ContentLayout, Divider, SchedulePicker } from '@digitalsettings/ui-lib';
import { nanoid } from 'nanoid';

type WorkSchedule = {
  fromHours: string;
  id: string;
  toHours: string;
  workDays: number[];
};

const getNewWorkTimeObject = () => ({
  id: nanoid(),
  fromHours: '',
  toHours: '',
  workDays: [1],
});

const Component = () => {
  const [workTime, setWorkTime] = useState<WorkSchedule>(getNewWorkTimeObject);

  const [workTimes] = useState<WorkSchedule[]>([getNewWorkTimeObject()]);

  return (
    <ContentLayout title="Schedule Picker">
      <Divider />
      <br />

      <div className="row">
        <div className="col-6 col-md-12">
          <SchedulePicker
            isSingle={true}
            isDisabled={false}
            workingSchedule={workTime}
            onChange={(schedule) => {
              console.log(schedule);
              setWorkTime(schedule);
            }}
          />
        </div>
        <div className="col-6 col-md-12">
          <SchedulePicker
            isSingle={false}
            workingSchedule={workTimes}
            onChange={(schedule) => {
              // console.log("onChange")
              // console.log(schedule);
            }}
          />
        </div>
      </div>
    </ContentLayout>
  );
};

export default Component;
