import HighligherLayout from '../../../components/HighligherLayout';

const Component = () => {
  return (
    <HighligherLayout label="Source:">
{`
import Icon from '../Icon';
import clsx from 'clsx';
import useFile from './useFile';

import styles from './styles.module.css';

export type MiniFileUploaderProps = {
  onFileSelect: (file: File) => void;
  text: string;
  imageUrl: string;
  disabled?: boolean;
};

const MiniFileUploader = ({ onFileSelect, text, imageUrl, disabled = false }: MiniFileUploaderProps) => {
  const { handelSelect, previewImage } = useFile({
    imageUrl,
    onFileSelect,
  });

  return (
    <div
      className={clsx(styles.uploadBox, {
        [styles.disabled]: disabled,
      })}
    >
      <div className={styles.imgBox}>
        {previewImage ? (
          <img src={previewImage} alt="" className={styles.image} />
        ) : (
          <Icon name="MdFileUpload" active size="small" />
        )}
      </div>
      <div className={styles.text}>{text}</div>
      <input type="file" disabled={disabled} onChange={handelSelect} className={styles.input} />
    </div>
  );
};

export default MiniFileUploader;
`}
    </HighligherLayout>
  );
};

export default Component;
