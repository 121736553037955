import HighligherLayout from '../../../components/HighligherLayout';

const Component = () => {
  return (
    <HighligherLayout label="Source:">
{`
import { memo, ReactNode } from 'react';
import { ToastContainer } from 'react-toastify';
import styles from './styles.module.css';

export type MainLayoutProps = {
  children?: ReactNode;
  header?: ReactNode;
  drawer?: ReactNode;
  isAuthorized?: boolean;
};

const MainLayout = ({ children, header, drawer, isAuthorized = false }: MainLayoutProps) => {
  if (isAuthorized) {
    return (
      <>
        <div className={styles.mainWrapper}>
          {drawer}
          <div className={styles.rightSide}>
            {header}
            <main className={styles.main}>
              {children}
            </main>
          </div>
        </div>
        <ToastContainer />
      </>
    );
  } else {
    return <div className={styles.content}>{children}</div>;
  }
};

export default memo<MainLayoutProps>(MainLayout);
`}
    </HighligherLayout>
  );
};

export default Component;