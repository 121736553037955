import HighligherLayout from '../../../components/HighligherLayout';

const Component = () => {
  return (
    <HighligherLayout label="Usage:">
{`
import { FormLabel, RadioGroup, Radio } from "@digitalsettings/ui-lib";

const Component = () => {
  return (
    <>
      <FormLabel>Form Label</FormLabel>
      <RadioGroup defaultValue="primary-radio" name="radio-buttons-group" row>
        <Radio label="Primary Radio" value="primary-radio" />
        <Radio label="Secondary Radio" value="secondary-radio" />
      </RadioGroup>
    </>
  );
};

export default Component;
`}
    </HighligherLayout>
  );
};

export default Component;
