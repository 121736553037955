import HighligherLayout from '../../../components/HighligherLayout';

const Component = () => {
  return (
    <HighligherLayout label="Source:">
{`
import { CSSProperties, memo, useEffect, useState } from 'react';
import clsx from 'clsx';
import Icon from '../Icon';
// import { useMediaQuery } from '../../hooks';
import { getRandomItem } from '../../helpers';
import styles from './styles.module.css';

type color = {
  color: CSSProperties['backgroundColor'];
  id: number | string;
  isSelected?: boolean;
};

export type ColorSelectProps = {
  colors: color[];
  isRequired?: boolean;
  onChange: (id: number | string | null) => void;
};

const CustomColorSelect = ({ colors = [], onChange, isRequired = false }: ColorSelectProps) => {
  // const mediaQueryXs = useMediaQuery('xs');
  const [selectedId, setSelectedId] = useState<string | number | null>('');

  const handleColorSelect = (id: string | number) => {
    if (id === selectedId) {
      setSelectedId(null);
      onChange(null);
    } else {
      setSelectedId(id);
      onChange(id);
    }
  };

  useEffect(() => {
    const selectedColor = colors.find(({ isSelected }) => isSelected);

    if (!selectedColor && isRequired) {
      const randomColor = getRandomItem(colors);
      setSelectedId(randomColor.id);
    } else if (selectedColor) {
      setSelectedId(selectedColor.id);
    }
  }, []);

  return (
    <div className={clsx(styles.container, 'd-flex', 'flex-wrap')}>
      {colors?.map((color) => (
        <div
          key={color.id}
          onClick={() => handleColorSelect(color.id)}
          style={{ backgroundColor: color.color }}
          className={clsx(styles.box, 'd-flex', 'align-items-center', 'justify-content-center')}
        >
          <div className={clsx(styles.icon, { [styles.checked]: color.id === selectedId })}>
            <Icon color="white" name="MdOutlineCheck" noGutters nonClickable />
          </div>
        </div>
      ))}
    </div>
  );
};

export default memo<ColorSelectProps>(CustomColorSelect);
`}
    </HighligherLayout>
  );
};

export default Component;
