import HighligherLayout from '../../../components/HighligherLayout';

const Component = () => {
  return (
    <HighligherLayout label="Usage:">
{`
import { useState } from "react";
import { ColorPicker } from "@digitalsettings/ui-lib";

const Component = () => {
  const [color, setColor] = useState("");
  const onChange = (newValue: string) => { setColor(newValue)};

  return (
    <ColorPicker
      label="Text Color"
      value={color}
      onChange={onChangeRgb}
      format="hex"
    />
  );
};

export default Component;
`}
    </HighligherLayout>
  );
};

export default Component;
