import { useState } from "react";
import {
  ContentLayout,
  Title,
  Divider,
  Switch,
  Menu,
  Icon,
  Button,
} from "@digitalsettings/ui-lib";
import Space from "../../components/Space";
import Source from "./Source";
import Usage from "./Usage";

const Component = () => {
  const [isDarkTheme, setIsDarkTheme] = useState(false);

  return (
    <ContentLayout title="Menu">
      <Divider />

      <Space>
        <Title variant="h3" gutterBottomSize="xs">
          Example:
        </Title>
        <div>
          <Menu
            size="large"
            component={
              <Button label="Dropdown Menu" variant="dropdown" />
            }
            items={[
              {
                shouldCloseMenu: false,
                onClick: () => setIsDarkTheme(!isDarkTheme),
                children: (
                  <>
                    <Icon
                      name="MdOutlineDarkMode"
                      label="Dark Theme"
                      nonClickable
                      noGutters
                    />
                    <Switch checked={isDarkTheme} noGutters />
                  </>
                ),
              },
              {
                children: (
                  <Icon
                    name="MdSpaceDashboard"
                    label="Menu Parameters"
                    nonClickable
                    noGutters
                  />
                ),
                onClick: () => console.log("Go To Menu Parameters"),
              },
            ]}
          />
        </div>
      </Space>

      <Space>
        <Usage />
      </Space>
      <Space>
        <Source />
      </Space>
    </ContentLayout>
  );
};

export default Component;
