import { ContentLayout, Title, Divider, DateTimePicker } from '@digitalsettings/ui-lib';

const Component = () => {
  return (
    <ContentLayout title="Date & Time Picker">
      <Divider />
      <br />

      <div className="row">
        <div className="col-5">
          <Title variant="h4" gutterSize="sm">
            Default
          </Title>
          <DateTimePicker label="Date Picker" value={null} onChange={() => {}} />
          <DateTimePicker label="Date Picker Error" error value={null} onChange={() => {}} />
          <DateTimePicker
            label="Date Picker Error Helper Text"
            error
            helperText="Helper text error"
            value={null}
            onChange={() => {}}
          />
          <Title variant="h4" gutterSize="sm">
            Filled
          </Title>
          <DateTimePicker label="Date Picker Value" value={Date.now()} onChange={() => {}} />
          <DateTimePicker label="Date Picker Value Error" error value={Date.now()} onChange={() => {}} />
          <DateTimePicker
            label="Date Picker Value Error Helper Text"
            error
            helperText="Helper text error"
            value={Date.now()}
            onChange={() => {}}
          />
          <Title variant="h4" gutterSize="sm">
            Default Disabled
          </Title>
          <DateTimePicker label="Date Picker" value={null} onChange={() => {}} disabled />
          <DateTimePicker label="Date Picker Error" error value={null} onChange={() => {}} disabled />
          <DateTimePicker
            label="Date Picker Error Helper Text"
            error
            helperText="Helper text error"
            value={null}
            onChange={() => {}}
            disabled
          />
          <Title variant="h4" gutterSize="sm">
            Filled Disabled
          </Title>
          <DateTimePicker label="Date Picker Value" value={Date.now()} onChange={() => {}} disabled />
          <DateTimePicker label="Date Picker Value Error" error value={Date.now()} onChange={() => {}} disabled />
          <DateTimePicker
            label="Date Picker Value Error Helper Text"
            error
            helperText="Helper text error"
            value={Date.now()}
            onChange={() => {}}
            disabled
          />
        </div>
        <div className="col-5">
          <Title variant="h4" gutterSize="sm">
            Default
          </Title>
          <DateTimePicker value={null} onChange={() => {}} />
          <DateTimePicker error value={null} onChange={() => {}} />
          <DateTimePicker error helperText="Helper text error" value={null} onChange={() => {}} />
          <Title variant="h4" gutterSize="sm">
            Filled
          </Title>
          <DateTimePicker value={Date.now()} onChange={() => {}} />
          <DateTimePicker error value={Date.now()} onChange={() => {}} />
          <DateTimePicker error helperText="Helper text error" value={Date.now()} onChange={() => {}} />
          <Title variant="h4" gutterSize="sm">
            Default Disabled
          </Title>
          <DateTimePicker value={null} onChange={() => {}} disabled />
          <DateTimePicker error value={null} onChange={() => {}} disabled />
          <DateTimePicker error helperText="Helper text error" value={null} onChange={() => {}} disabled />
          <Title variant="h4" gutterSize="sm">
            Filled Disabled
          </Title>
          <DateTimePicker value={Date.now()} onChange={() => {}} disabled />
          <DateTimePicker error value={Date.now()} onChange={() => {}} disabled />
          <DateTimePicker error helperText="Helper text error" value={Date.now()} onChange={() => {}} disabled />
        </div>
      </div>
    </ContentLayout>
  );
};

export default Component;
