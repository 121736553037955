import { ContentLayout, Select, Divider, Title } from "@digitalsettings/ui-lib";
import Space from "../../components/Space";
import Source from "./Source";
import Usage from "./Usage";

const Component = () => {
  return (
    <ContentLayout title="Select">
      <Divider />

      <Space>
        <Title variant="h3" gutterBottomSize="xs">
          Example:
        </Title>
        <div>
          <Select
            onValueChange={(item) => console.log(item)}
            data={selectDataNoChildren}
            mode="singleSelect"
            label="Choose"
          />
        </div>
      </Space>

      <Space>
        <Usage />
      </Space>
      <Space>
        <Source />
      </Space>
    </ContentLayout>
  );
};

export default Component;

const selectData = [
  { id: 1, label: "label 1", value: "select 1", children: [] },
  { id: 2, label: "label 2", value: "select 2", children: [] },
  {
    id: 3,
    label: "label 3",
    value: "select 3",
    children: [
      {
        id: 5,
        label: "label 5",
        value: "select 5",
        children: [
          { id: 6, label: "label 6", value: "select 6", children: [] },
          { id: 7, label: "label 7", value: "select 7", children: [] },
        ],
      },
      { id: 4, label: "label 4", value: "select 4", children: [] },
    ],
  },
  { id: 8, label: "label 1", value: "select 1", children: [] },
  { id: 9, label: "label 1", value: "select 1", children: [] },
  { id: 10, label: "label 1", value: "select 1", children: [] },
  { id: 11, label: "label 2", value: "select 2", children: [] },
];

const selectDataNoChildren = selectData.map((item) => {
  const { id, label, value } = item;
  return { id, label, value };
});