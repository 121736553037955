import HighligherLayout from '../../../components/HighligherLayout';

const Component = () => {
  return (
    <HighligherLayout label="Source:">
{`
import { memo } from 'react';
import MuiChip, { ChipProps as MuiChipProps } from '@mui/material/Chip';

import './style.css';

export type ChipProps = MuiChipProps;

export const Chip = ({ variant = 'filled', deleteIcon = <div></div>, ...props }: ChipProps) => (
  <MuiChip variant={variant} deleteIcon={deleteIcon} {...props} />
);

export default memo<ChipProps>(Chip);
`}
    </HighligherLayout>
  );
};

export default Component;
