import React, { useState } from "react";
import {
  ContentLayout,
  Title,
  Divider,
  TabContext,
  TabList,
  Tab,
  TabPanel,
} from "@digitalsettings/ui-lib";
import Space from "../../components/Space";
import Source from "./Source";
import Usage from "./Usage";

const Component = () => {
  const [value, setValue] = useState("1");
  const handleChange = (event: React.SyntheticEvent, newValue: string) => setValue(newValue);

  return (
    <ContentLayout title="Tabs">
      <Divider />

      <Space>
        <Title variant="h3" gutterBottomSize="xs">
          Example:
        </Title>
        <div>
          <TabContext value={value}>
            <TabList onChange={handleChange}>
              <Tab
                label="Georgian"
                value="1"
                imgUrl="https://meamacorecdn.azureedge.net/languages/c8919090-413b-4f20-8b16-4b4254031a89.svg"
              />
              <Tab
                label="English"
                disabled
                value="2"
                imgUrl="https://meamacorecdn.azureedge.net/languages/ab28c01d-ac16-4a50-8f0d-784c7a1b9e9c.svg"
              />
              <Tab
                label="German"
                value="3"
                imgUrl="https://meamacorecdn.azureedge.net/languages/ae371e92-4d0d-41bb-8512-f39b9a4cce59.svg"
              />
              <Tab
                label="Ukraine"
                value="4"
                imgUrl="https://meamacorecdn.azureedge.net/languages/7f54fc53-822b-491d-921a-b04a7471f8ce.svg"
              />
            </TabList>
            <TabPanel value="1">Georgian Content ...</TabPanel>
            <TabPanel value="2">English Content ...</TabPanel>
            <TabPanel value="3">German Content ...</TabPanel>
            <TabPanel value="4">Ukraine Content ...</TabPanel>
          </TabContext>
        </div>
      </Space>

      <Space>
        <Usage />
      </Space>
      <Space>
        <Source />
      </Space>
    </ContentLayout>
  );
};

export default Component;
