import HighligherLayout from '../../../components/HighligherLayout';

const Component = () => {
  return (
    <HighligherLayout label="Usage:">
{`
import { Breadcrumbs } from "@digitalsettings/ui-lib";

const Component = () => {
  return (
    <Breadcrumbs
      breadcrumbs={[
        {
          name: "Home",
          to: "/",
          isValidUrl: true,
        },
        {
          name: "Ecommerce",
          to: "/",
          isValidUrl: true,
        },
        {
          name: "Catalog",
          to: "/",
          isValidUrl: false,
        },
        {
          name: "Add Or Update",
          to: "/",
          isValidUrl: false,
        },
      ]}
    />
  );
};

export default Component;
`}
    </HighligherLayout>
  );
};

export default Component;
