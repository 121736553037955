import HighligherLayout from '../../../components/HighligherLayout';

const Component = () => {
  return (
    <HighligherLayout label="Source:">
{`
import { forwardRef, memo, useState } from 'react';
import clsx from 'clsx';
import Icon from '../Icon';
import { IconName } from '../Icon/icons';
import InputAdornment from '../InputAdornment';
import MTextField, { MTextFieldProps } from '../MTextField';

export type SearchVarinatType = 'primarySearch' | 'secondarySearch';

export type TextFieldProps = MTextFieldProps & {
  isPassword?: boolean;
  iconName?: IconName;
  noGutters?: boolean;
  searchVarinat?: SearchVarinatType;
  onClickIcon?: () => void;
};

export const CustomTextField = forwardRef<HTMLDivElement>(
  (
    {
      isPassword = false,
      iconName,
      noGutters = false,
      className,
      searchVarinat,
      onClickIcon,
      ...props
    }: TextFieldProps,
    ref
  ) => {
    const [showPassword, setShowPassword] = useState<boolean>(!!isPassword);
    const handleClickShowPassword = () => setShowPassword(!showPassword);

    const inputIcon = isPassword ? (
      <InputAdornment position="end">
        <Icon
          name={showPassword ? 'AiFillEye' : 'AiFillEyeInvisible'}
          onClick={handleClickShowPassword}
          disabled={props.disabled}
        />
      </InputAdornment>
    ) : iconName ? (
      <InputAdornment position="end">
        <Icon
          name={iconName}
          onClick={onClickIcon}
          nonClickable={!onClickIcon}
          disabled={props.disabled}
          noGutters={searchVarinat === 'primarySearch'}
          color={searchVarinat === 'primarySearch' ? 'black' : 'default'}
        />
      </InputAdornment>
    ) : null;

    return (
      <MTextField
        ref={ref}
        className={clsx({
          'MuiTextField-hasLabel': !props.label,
          'MuiTextField-hasIcon': isPassword || iconName || className === 'MuiTextField-hasIcon',
          'MuiTextField-primarySearch': searchVarinat && searchVarinat === 'primarySearch',
          'MuiTextField-secondarySearch': searchVarinat && searchVarinat === 'secondarySearch',
          'MuiTextField-no-gutters': noGutters,
        })}
        type={!showPassword ? 'text' : 'password'}
        InputProps={{
          endAdornment: inputIcon,
        }}
        {...props}
      />
    );
  }
);

export default memo<TextFieldProps>(CustomTextField);
`}
    </HighligherLayout>
  );
};

export default Component;