import HighligherLayout from '../../../components/HighligherLayout';

const Component = () => {
  return (
    <HighligherLayout label="Usage:">
{`
import { CollapseBox, Text } from "@digitalsettings/ui-lib";

const Component = () => {
  return (
    <CollapseBox title="Collapse Box Title">
      <Text>
        Lorem ipsum dolor sit amet consectetur, adipisicing elit. Quas
        temporibus autem laborum. Unde rem dolore est mollitia pariatur
        ullam adipisci velit corporis vitae itaque animi quaerat.
      </Text>
    </CollapseBox>
  );
};

export default Component;
`}
    </HighligherLayout>
  );
};

export default Component;
