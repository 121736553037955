import HighligherLayout from '../../../components/HighligherLayout';

const Component = () => {
  return (
    <HighligherLayout label="Source:">
{`
import { memo, ReactNode } from 'react';
import clsx from 'clsx';
import styles from './styles.module.css';

export type TextProps = {
  gutterTopSize?: 'xl' | 'lg' | 'md' | 'sm' | 'xs';
  gutterBottomSize?: 'xl' | 'lg' | 'md' | 'sm' | 'xs';
  gutterSize?: 'xl' | 'lg' | 'md' | 'sm' | 'xs';
  color?: 'default' | 'black' | 'white' | 'dark' | 'medium' | 'light' | 'success' | 'error';
  size?: 'small' | 'medium' | 'large';
  bold?: boolean;
  upper?: boolean;
  children?: ReactNode;
};

const Text = ({
  gutterTopSize,
  gutterBottomSize,
  gutterSize,
  color = 'default',
  size = 'medium',
  bold = false,
  upper = false,
  children,
  ...props
}: TextProps) => {
  return (
    <p
      className={clsx(styles.text, {
        'f-bold': bold,
        'f-upper': upper,
        [styles.gutterTopSizeXl]: gutterTopSize === 'xl',
        [styles.gutterTopSizeLg]: gutterTopSize === 'lg',
        [styles.gutterTopSizeMd]: gutterTopSize === 'md',
        [styles.gutterTopSizeSm]: gutterTopSize === 'sm',
        [styles.gutterTopSizeXs]: gutterTopSize === 'xs',
        [styles.gutterBottomSizeXl]: gutterBottomSize === 'xl',
        [styles.gutterBottomSizeLg]: gutterBottomSize === 'lg',
        [styles.gutterBottomSizeMd]: gutterBottomSize === 'md',
        [styles.gutterBottomSizeSm]: gutterBottomSize === 'sm',
        [styles.gutterBottomSizeXs]: gutterBottomSize === 'xs',
        [styles.gutterSizeXl]: gutterSize === 'xl',
        [styles.gutterSizeLg]: gutterSize === 'lg',
        [styles.gutterSizeMd]: gutterSize === 'md',
        [styles.gutterSizeSm]: gutterSize === 'sm',
        [styles.gutterSizeXs]: gutterSize === 'xs',
        [styles.colorDefault]: color === 'default',
        [styles.colorBlack]: color === 'black',
        [styles.colorWhite]: color === 'white',
        [styles.colorDark]: color === 'dark',
        [styles.colorMedium]: color === 'medium',
        [styles.colorLight]: color === 'light',
        [styles.colorSuccess]: color === 'success',
        [styles.colorError]: color === 'error',
        [styles.small]: size === 'small',
        [styles.medium]: size === 'medium',
        [styles.large]: size === 'large',
      })}
      {...props}
    >
      {children}
    </p>
  );
};

export default memo<TextProps>(Text);
`}
    </HighligherLayout>
  );
};

export default Component;
