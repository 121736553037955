import { ContentLayout, Title, Divider, UserMenu, Icon } from "@digitalsettings/ui-lib";
import Space from "../../components/Space";
import Source from "./Source";
import Usage from "./Usage";

const Component = () => {
  return (
    <ContentLayout title="User Menu">
      <Divider />

      <Space>
        <Title variant="h3" gutterBottomSize="xs">
          Example:
        </Title>
        <div>
          <UserMenu
            name="David Savaneli"
            email="savaneli.david@gmail.com"
            showBadge={true}
            onClick={() => console.log("open menu")}
            menuItems={[
              {
                children: (
                  <Icon
                    name="MdNotifications"
                    label="Notifications"
                    nonClickable
                    noGutters
                    badge={2}
                  />
                ),
              },
              {
                children: (
                  <Icon
                    name="MdPassword"
                    label="Change Password"
                    nonClickable
                    noGutters
                  />
                ),
              },
              {
                children: (
                  <Icon
                    name="MdManageAccounts"
                    label="Profile"
                    nonClickable
                    noGutters
                  />
                ),
              },
              {
                children: (
                  <Icon
                    name="MdLogout"
                    label="Logout"
                    nonClickable
                    noGutters
                  />
                ),
              },
            ]}
          />
        </div>
      </Space>

      <Space>
        <Usage />
      </Space>
      <Space>
        <Source />
      </Space>
    </ContentLayout>
  );
};

export default Component;
