import HighligherLayout from '../../../components/HighligherLayout';

const Component = () => {
  return (
    <HighligherLayout label="Source:">
{`
import { memo } from 'react';
import clsx from 'clsx';
import Icon from '../Icon';
import { IconName } from '../Icon/icons';
import styles from './styles.module.css';

export type AlertProps = {
  iconName?: IconName;
  title?: string;
  text?: string;
  variant?: 'default' | 'success' | 'error' | 'warning' | 'info';
};

const Alert = ({ title, text, iconName, variant = 'default' }: AlertProps) => {
  const icon = () => {
    if (variant === 'default') return '';
    else if (variant === 'success') return 'MdCheckCircle';
    else if (variant === 'error') return 'MdError';
    else if (variant === 'warning') return 'MdWarning';
    else if (variant === 'info') return 'MdInfo';
    else return '';
  };
  return (
    <div
      className={clsx(styles.wrapper, {
        [styles.default]: variant === 'default',
        [styles.success]: variant === 'success',
        [styles.error]: variant === 'error',
        [styles.warning]: variant === 'warning',
        [styles.info]: variant === 'info',
      })}
    >
      {(iconName || icon()) && (
        <div className={styles.iconBox}>
          <Icon className={styles.icon} name={iconName ? iconName : icon()} size="medium" noGutters />
        </div>
      )}

      <div className={styles.info}>
        {title && <div className={clsx(styles.title, 'f-bold')}>{title}</div>}
        {text && <div className={styles.text}>{text}</div>}
      </div>
    </div>
  );
};

export default memo<AlertProps>(Alert);
`}
    </HighligherLayout>
  );
};

export default Component;
