import HighligherLayout from '../../../components/HighligherLayout';

const Component = () => {
  return (
    <HighligherLayout label="Source:">
{`
import { memo } from 'react';
import clsx from 'clsx';
import Tooltip from '../Tooltip';
import styles from './styles.module.css';

export type ColorPreviewProps = {
  color?: string;
  size?: 'small' | 'medium' | 'large';
};

const ColorPreview = ({ color, size = 'medium' }: ColorPreviewProps) => {
  return (
    <Tooltip title={color}>
      <div
        style={{ backgroundColor: color ?? '' }}
        className={clsx(styles.box, {
          [styles.small]: size === 'small',
          [styles.medium]: size === 'medium',
          [styles.large]: size === 'large',
        })}
      ></div>
    </Tooltip>
  );
};

export default memo<ColorPreviewProps>(ColorPreview);
`}
    </HighligherLayout>
  );
};

export default Component;