import HighligherLayout from '../../../components/HighligherLayout';

const Component = () => {
  return (
    <HighligherLayout label="Source:">
{`
import {ChangeEvent, memo, useEffect, useRef, useState} from 'react';
import TextField, { SearchVarinatType, TextFieldProps } from '../TextField';
import MenuItem from '../MenuItem';
import NoData from '../NoData';
import { useOutsideClick, useListNavigation } from '../../hooks';

import styles from './styles.module.css';
import clsx from 'clsx';
import { scrollIfFocused } from '../../utils';

type Suggestion = { [name: string]: string };

export type SearchProps = TextFieldProps & {
  searchVariant?: SearchVarinatType;
  hasSuggestions?: boolean;
  suggestions?: Suggestion[];
  dataLabel?: keyof Suggestion;
  dataValue?: keyof Suggestion;
  minLettersToShowSuggestions?: number;
  noGutters?: boolean;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  onSuggestionSelect?: (value: Suggestion) => void;
};

const Search = ({
  searchVariant = 'primarySearch',
  hasSuggestions = false,
  suggestions,
  onChange,
  minLettersToShowSuggestions = 1,
  noGutters = false,
  dataLabel = 'label',
  dataValue = 'value',
  onSuggestionSelect: onSelect,
  ...props
}: SearchProps) => {
  const [isSuggestionsShown, setIsSuggestionsShown] = useState(false);
  const suggestionsRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLDivElement>(null);

  const { setSelectedIndex, isSelectedIndex } = useListNavigation({
    listLength: suggestions?.length,
    onSelect: (selectedIndex) => {
      if (!suggestions || selectedIndex == null || !onSelect) return;

      onSelect(suggestions[selectedIndex]);
      setIsSuggestionsShown(false);
    },
  });

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    onChange?.(e);

    if (value.length <= minLettersToShowSuggestions) {
      setIsSuggestionsShown(false);
      return;
    } else if (hasSuggestions && !isSuggestionsShown) {
      setIsSuggestionsShown(true);
    }

    setSelectedIndex(null);
  };

  const handleSuggestionClick = (value: Suggestion) => {
    onSelect?.(value);
    setIsSuggestionsShown(false);
  };

  useOutsideClick(suggestionsRef, () => {
    setIsSuggestionsShown(false);
  });

  useEffect(() => {
    const handleKeyPress = (e: KeyboardEvent) => {
      if (e.key === "ArrowUp") {
        e.preventDefault();
      }
    }

    inputRef.current?.addEventListener('keydown', handleKeyPress)

    const inputRefVar = inputRef.current
    return () => {
      inputRefVar?.removeEventListener('keydown', handleKeyPress);
    }
  }, [])

  return (
    <div ref={suggestionsRef} className={styles.customSearch}>
      <TextField
        ref={inputRef}
        searchVarinat={searchVariant}
        placeholder="Search"
        iconName="MdSearch"
        noGutters={noGutters}
        onChange={handleChange}
        {...props}
      />
      {isSuggestionsShown && (
        <div data-testid="suggestionsContainer" className={styles.suggestionsBox}>
          {suggestions?.length ? (
            suggestions?.map((option, i) => {
              const isFocused = isSelectedIndex(i);

              const label = option[dataLabel];
              const value = option[dataValue];

              return (
                <MenuItem
                  key={value}
                  ref={(ref) => scrollIfFocused(ref, isFocused)}
                  className={clsx({ [styles.focused]: isFocused })}
                  onClick={() => handleSuggestionClick(option)}
                  value={value}
                >
                  <div className={styles.suggestionItem}>{label}</div>
                </MenuItem>
              );
            })
          ) : (
            <NoData text="Results Not Found" />
          )}
        </div>
      )}
    </div>
  );
};

export default memo<SearchProps>(Search);
`}
    </HighligherLayout>
  );
};

export default Component;
