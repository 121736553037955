import HighligherLayout from '../../../components/HighligherLayout';

const Component = () => {
  return (
    <HighligherLayout label="Source:">
{`
import { memo } from 'react';
import Icon from '../Icon';
import { IconName } from '../Icon/icons';
import { ChangeEvent, useState } from 'react';
import clsx from 'clsx';
import { isWholeNumber } from '../../helpers';

import styles from './styles.module.css';

export type QuantitySelectorProps = {
  size?: 'small' | 'medium' | 'large';
  isDisabled?: boolean;
  value: number;
  maxQuantity: number;
  minQuantity?: number;
  onValueChange: (val: number) => void;
};

const QuantitySelector = ({
  size = 'medium',
  isDisabled = false,
  value = 0,
  onValueChange,
  minQuantity = 0,
  maxQuantity = 0,
}: QuantitySelectorProps) => {
  if (minQuantity > maxQuantity) {
    throw new Error('minQuantity must be less than maxQuantity');
  }

  const [localValue, setLocalValue] = useState<number | undefined>(
    value !== undefined ? Math.max(minQuantity, Math.min(maxQuantity, value)) : undefined
  );
  const isMaximum = localValue === maxQuantity;
  const isMinQuantity = localValue === minQuantity;

  const updateState = (val: number) => {
    setLocalValue(val);
    onValueChange(val);
  };

  const handleIncrement = () => {
    if (localValue === undefined) updateState(minQuantity);
    else if (localValue < maxQuantity) updateState(localValue + 1);
  };

  const handleDecrement = () => {
    if (localValue === undefined) updateState(minQuantity);
    else if (localValue > minQuantity) updateState(localValue - 1);
  };

  const handleQuantityInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.valueAsNumber;

    if (!value) {
      return setLocalValue(undefined);
    } else if (!isWholeNumber(value)) {
      return;
    } else if (value > maxQuantity) {
      return updateState(maxQuantity);
    } else if (value < minQuantity) {
      return updateState(minQuantity);
    }

    updateState(value);
  };

  const onFocus = (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.valueAsNumber) e.target.value = '';
  };

  const onBlur = (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.value) {
      updateState(minQuantity);
      if (minQuantity === 0) e.target.valueAsNumber = 0;
    }
  };

  return (
    <div
      className={clsx(styles.qSelector, {
        [styles.small]: size === 'small',
        [styles.medium]: size === 'medium',
        [styles.large]: size === 'large',
        [styles.qSelectorDisabled]: isDisabled,
      })}
    >
      <QSelectorButton size={size} icon="MdRemove" onClick={handleDecrement} isDisabled={isMinQuantity} />
      <input
        value={localValue}
        type="number"
        className={styles.qSelectorQuantity}
        onChange={handleQuantityInputChange}
        onFocus={onFocus}
        onBlur={onBlur}
      />
      <QSelectorButton size={size} icon="MdAdd" onClick={handleIncrement} isDisabled={isMaximum} />
    </div>
  );
};

export default memo<QuantitySelectorProps>(QuantitySelector);

type QSelectorButtonProps = {
  size?: 'small' | 'medium' | 'large';
  isDisabled: boolean;
  icon: IconName;
  onClick: () => void;
};

const QSelectorButton = ({ size = 'medium', isDisabled, icon, onClick }: QSelectorButtonProps) => {
  return (
    <button className={clsx(styles.qSelectorButton, { [styles.disabledBtn]: isDisabled })} onClick={onClick}>
      <Icon name={icon} noGutters size={size} />
    </button>
  );
};
`}
    </HighligherLayout>
  );
};

export default Component;
