import HighligherLayout from '../../../components/HighligherLayout';

const Component = () => {
  return (
    <HighligherLayout label="Usage:">
{`
import { FormLabel, FormGroup, Checkbox } from "@digitalsettings/ui-lib";

const Component = () => {
  return (
    <>
      <FormLabel>Form Label</FormLabel>
      <FormGroup row>
        <Checkbox label="Primary Checkbox" value="primary-checkbox" />
        <Checkbox label="Secondary Checkbox" value="secondary-checkbox" />
      </FormGroup>
    </>
  );
};

export default Component;
`}
    </HighligherLayout>
  );
};

export default Component;
