import HighligherLayout from '../../../components/HighligherLayout';

const Component = () => {
  return (
    <HighligherLayout label="Source:">
{`
import { memo, useEffect } from 'react';
import Icon from '../Icon';
import { IconName } from '../Icon/icons';
import styles from './styles.module.css';

export type ViewTogglerItem = { iconName: IconName; viewName: string; isDefault: boolean };

export type ViewTogglerProps = {
  items: ViewTogglerItem[];
  currentViewName: string;
  handleViewSelect: (viewName: string) => void;
};

const ViewToggler = ({ currentViewName, items, handleViewSelect }: ViewTogglerProps) => {
  useEffect(() => {
    if (currentViewName) return;

    const defaultItem = items.find(({ isDefault }) => isDefault)?.viewName;
    if (!defaultItem) return;

    handleViewSelect(defaultItem);
  }, []);

  return (
    <div className={styles.items}>
      {items.map(({ iconName, viewName }) => (
        <div className={styles.item} key={viewName}>
          <Icon name={iconName} onClick={() => handleViewSelect(viewName)} active={viewName === currentViewName} />
        </div>
      ))}
    </div>
  );
};

export default memo<ViewTogglerProps>(ViewToggler);
`}
    </HighligherLayout>
  );
};

export default Component;
